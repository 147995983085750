export const DBCOLLECTIONS = {
  STORES: "stores",
  USERS: "users",
  QUEUE: "queue",
  STORE_NEXT_ID: "store_next_id",
};

export const DBCONSTANTS = {
  DOC_IDS: {
    TOKEN_NO: "token_nos",
    STORE_CODE: "store_code",
  },
};
