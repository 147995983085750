import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { constants as rfConstants } from "redux-firestore";
import {
  actionTypes as rrfActionTypes,
  getFirebase,
} from "react-redux-firebase";

const extraArgument = { getFirebase };
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        // just ignore every redux-firebase and react-redux-firebase action type
        ...Object.keys(rfConstants.actionTypes).map(
          (type) => `${rfConstants.actionsPrefix}/${type}`
        ),
        ...Object.keys(rrfActionTypes).map(
          (type) => `@@reactReduxFirebase/${type}`
        ),
      ],
      ignoredPaths: ["firebase", "firestore"],
    },
    immutableCheck: {
      ignoredPaths: ["firebase", "firestore"],
    },
    thunk: {
      extraArgument,
    },
  }),
];

export default middleware;
