import Modal from "../Modal";
import React, { useEffect } from "react";
import { register } from "../../serviceWorkerRegistration";
import { useAppDispatch, useAppSelector } from "../../reduxstore";
import {
  setUpdateAvailable,
  ignoreUpdate,
} from "../../reduxstore/reducers/app";
import "./style.css";
import { useTranslation } from "react-i18next";

const AppUpdate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const serviceWorkerRegistration = useAppSelector((s) => s.app.registration);
  const updateAvailable = useAppSelector((s) => s.app.updateAvailable);

  useEffect(() => {
    register({
      onSuccess: () => {
        console.log("SW successfully registered");
      },
      onUpdate: (registration: any) =>
        dispatch(setUpdateAvailable(registration)),
    });
  }, [dispatch]);

  const handleDismiss = () => dispatch(ignoreUpdate());

  const handleReloadApp = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", (e: any) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <Modal displayed={updateAvailable} onDismiss={handleDismiss}>
      <div className="app_update-body">{t("app_update_message")}</div>
      <div className="app_update-buttons">
        <div className="app_update-btn--reject" onClick={() => handleDismiss()}>
          {t("app_update_reject")}
        </div>
        <div
          className="app_update-btn--accept"
          onClick={() => handleReloadApp()}
        >
          {t("app_update_accept")}
        </div>
      </div>
    </Modal>
  );
};

export default AppUpdate;
