import React, { useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from "react-redux-firebase";
import Loading from "components/Loading";
import Layout, { Bottom, Middle } from "components/FullHeightLayout";
import { useAppSelector } from "reduxstore";
import { Shopper } from "reduxstore/types";
import { URLS } from "utils/constants";
import ShopSummary from "./ShopSummary";
import QueueSummary from "./QueueSummary";
import UserStatus, { UserStatusNoQueue } from "./UserStatus";
import ActionText from "./ActionText";
import ActionButton from "./ActionButton";

const ShopperStoreScreen = () => {
  const { id } = useParams<{ id: string }>();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;
  const [approved, setApproved] = useState(false);

  const loadingCompleted = useAppSelector((state) => {
    return (
      state.firestore.status.requested[`stores/${id}`] &&
      state.firestore.status.requested.queue &&
      state.firestore.status.requested.userInQueue
    );
  });
  const store = useAppSelector(
    (state) => state.firestore.data.stores && state.firestore.data.stores[id]
  );
  const queue = useAppSelector(
    (state) => state.firestore.data.queue && state.firestore.ordered.queue[0] // to get with id
  );
  const shopper = useAppSelector<Shopper>(
    (state) => state.firestore.data.userInQueue
  );

  useFirestoreConnect(() => {
    const listeners: ReduxFirestoreQuerySetting[] = [
      { collection: "stores", doc: id },
    ];

    if (store) {
      listeners.push({
        collection: "stores",
        doc: id,
        subcollections: [
          {
            collection: "queues",
            doc: store.activeQueueId,
          },
        ],
        storeAs: "queue",
      });
    }

    if (queue) {
      const colShoppers = { collection: "shoppers", doc: userId };
      const colQueue = {
        collection: "queues",
        doc: store.activeQueueId,
        subcollections: [colShoppers],
      };
      listeners.push({
        collection: "stores",
        doc: id,
        subcollections: [colQueue],
        storeAs: "userInQueue",
      });
    }
    return listeners;
  });

  if (!loadingCompleted) return <Loading />;

  if (store === null) {
    return <Redirect to={URLS.pageNotFound} />;
  }

  if (!queue || !queue.isActive) {
    return (
      <div>
        <ShopSummary store={store} />
        <Middle>
          <UserStatusNoQueue />
        </Middle>
      </div>
    );
  }

  return (
    <Layout>
      <ShopSummary store={store} />
      <UserStatus
        queue={queue}
        status={shopper && shopper.status}
        ticketNo={shopper && shopper.ticketNo}
      />
      <QueueSummary userInQueue={shopper} storeId={id} queue={queue} />
      <Bottom>
        <ActionText
          storeId={id}
          queue={queue}
          shopper={shopper}
          approved={approved}
          setApproved={setApproved}
        />
        <ActionButton
          approved={approved}
          shopper={shopper}
          storeId={id}
          queue={queue}
        />
      </Bottom>
    </Layout>
  );
};

export default ShopperStoreScreen;
