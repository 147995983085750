import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { StoreCreateData } from "../../../reduxstore/types";
import TermsAndConditions from "../../Shopper/Store/TermsAndConditions";
import Thumb from "../../../components/Thumb";
import { LogoBig } from "../../../components/Logo";
import Layout, { Bottom } from "../../../components/FullHeightLayout";
import { Redirect, useHistory } from "react-router-dom";
import { URLS } from "../../../utils/constants";
import { useAppSelector } from "../../../reduxstore";
import FeedbackText from "../../../components/FeedbackText";

interface Props {
  handleConfirm: () => void;
  goBackToFirstStep: () => void;
  store: StoreCreateData;
}

const StepConfirm = ({ store, handleConfirm, goBackToFirstStep }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [approved, setApproved] = useState(false);
  const saveStoreStatus = useAppSelector(
    (state) => state.store.saveStoreStatus
  );
  const savedStoreId = useAppSelector((state) => state.store.savedStoreId);

  useEffect(() => {
    if (saveStoreStatus === "succeeded" && savedStoreId) {
      history.push(
        URLS.storeManager.storeDashboard.replace(":id", savedStoreId)
      );
    }
  }, [history, savedStoreId, saveStoreStatus]);

  if (!store.name || !store.intro) {
    return <Redirect to={URLS.storeManager.createStore} />;
  }

  return (
    <Layout>
      <div className="store-form-header spaced">
        <LogoBig />
        <div className="store-form-title">{t(`store_form_confirm_title`)}</div>
        <div className="store-form-desc">{t(`store_form_confirm_desc`)}</div>
      </div>
      <div className="store-form-confirm-summary-container spaced">
        <Thumb src={store.imageURL} />
        <div className="store-form-confirm-summary">
          <span className="store-form-confirm-name">{store.name}</span>
          <span>{store.intro}</span>
        </div>
      </div>
      <div className="store-form-confirm-goback-container spaced">
        <Button
          label={t("store_form_goback")}
          onClick={goBackToFirstStep}
          color={"secondary"}
        />
        <div className="">{t("store_form_confirm_dontworry")}</div>
      </div>
      <Bottom>
        <div style={{ marginBottom: "1em" }}>
          <TermsAndConditions approved={approved} setApproved={setApproved} />
        </div>
        {saveStoreStatus === "loading" ? (
          <FeedbackText loading={true} />
        ) : (
          <Button
            label={t("confirm")}
            onClick={handleConfirm}
            disabled={!approved}
          />
        )}
      </Bottom>
    </Layout>
  );
};

export default StepConfirm;
