import firebaseApp from "api/firebase.config";
import { FB_EVENT_NAMES } from "../utils/constants";
import configs from "config/configs";

export class AnalyticsService {
  firebaseAnalytics: firebaseApp.analytics.Analytics;

  constructor() {
    this.firebaseAnalytics = firebaseApp.analytics();
  }

  async log(eventName: string, content: { [key: string]: any }) {
    this.firebaseAnalytics.setAnalyticsCollectionEnabled(
      localStorage.getItem(configs.cookieConsentKey) === "accepted"
    );
    this.firebaseAnalytics.logEvent(eventName, content);
  }

  async storeCreated(id: string) {
    await this.log(FB_EVENT_NAMES.storeCreated, {
      storeId: id,
    });
  }
}
