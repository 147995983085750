import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import store from "./store";
import user from "./user";
import app from "./app";

export default combineReducers({
  store: store.reducer,
  user: user.reducer,
  app: app.reducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});
