import firebaseApp from "api/firebase.config";
import { BaseService } from "./BaseService";

export interface EmailNotification {
  to: string;
  subject: string;
  body: string;
}

export interface SMSNotification {
  to: string;
  text: string;
}

class NotificationService extends BaseService {
  async email(storeId: string, data: EmailNotification) {
    const newDocRef = this.firestore
      .collection("stores")
      .doc(storeId)
      .collection("notifications")
      .doc();
    const id = newDocRef.id;

    await newDocRef.set({
      type: "email",
      to: data.to,
      subject: data.subject,
      body: data.body,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    });

    return id;
  }

  async sms(storeId: string, data: SMSNotification) {
    const newDocRef = this.firestore
      .collection("stores")
      .doc(storeId)
      .collection("notifications")
      .doc();
    const id = newDocRef.id;

    await newDocRef.set({
      type: "sms",
      to: `${data.to}`,
      text: data.text,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    });

    return id;
  }
}

export default new NotificationService(firebaseApp.firestore());
