import React from "react";
import { Queue, ShopperQueueState } from "reduxstore/types";
import iconThumbs from "assets/ic_thumb_up_24px.svg";
import "./style.css";
import { useTranslation } from "react-i18next";
import { Middle } from "../../../../components/FullHeightLayout";

interface UserStatusProps {
  ticketNo?: number;
  status?: ShopperQueueState;
  queue: Queue;
}

interface SubStatusProps {
  ticketNo: number;
}

interface DefaultProps {
  title: string;
  description: string;
}

const Default = ({ title, description }: DefaultProps) => {
  return (
    <div className="shopper-user-status spaced">
      <div className="shopper-user-status-title">{title}</div>
      <div className="shopper-user-status-desc">{description}</div>
    </div>
  );
};

const Waiting = ({ ticketNo }: SubStatusProps) => {
  const { t } = useTranslation();

  return (
    <div className="shopper-user-status spaced">
      <div className="shopper-user-status-title">{t("your_ticket_number")}</div>
      <div className="shopper-user-status-desc-big">{ticketNo}</div>
    </div>
  );
};
const InStore = ({ ticketNo }: SubStatusProps) => {
  const { t } = useTranslation();

  return (
    <div className="shopper-user-status spaced">
      <div className="shopper-user-status-title">
        {t("your_ticket_number")} {ticketNo}
      </div>
      <div className="shopper-user-status-desc-big">
        <img alt="" src={iconThumbs} />
      </div>
    </div>
  );
};

export const UserStatusNoQueue = () => {
  const { t } = useTranslation();
  return (
    <Default
      title={t("shopper_no_queue_title")}
      description={t("shopper_no_queue_desc")}
    />
  );
};

export const UserStatusNoTicket = () => {
  const { t } = useTranslation();
  return (
    <Default
      title={t("shopper_no_ticket_title")}
      description={t("shopper_no_ticket_desc")}
    />
  );
};

const UserStatus = ({ ticketNo, status, queue }: UserStatusProps) => {
  const { t } = useTranslation();

  if (status === "let_in" || status === "to_approve" || status === "approved") {
    return (
      <div className="shopper-user-status-container shopper-user-status-container-hl">
        <div className="shopper-user-status spaced">
          <div className="shopper-user-status-title">
            {t("your_ticket_number")}
          </div>
          <div className="shopper-user-status-desc-big">{ticketNo}</div>
        </div>
      </div>
    );
  }

  const getBody = () => {
    if (status === "waiting") return <Waiting ticketNo={ticketNo!} />;
    if (status === "in_store") return <InStore ticketNo={ticketNo!} />;

    if (!queue.isTicketingOpen) {
      return (
        <Middle>
          <UserStatusNoTicket />
        </Middle>
      );
    }

    return (
      <Default
        title={t("shopper_before_queue_title")}
        description={t("shopper_before_queue_desc")}
      />
    );
  };

  const classes = ["shopper-user-status-container"];

  return <div className={classes.join(" ")}>{getBody()}</div>;
};

export default UserStatus;
