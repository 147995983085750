import React, { useEffect } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import LocationInput from "../LocationInput";
import { Store } from "../../../reduxstore/types";
import { LogoBig } from "../../../components/Logo";
import { useAppDispatch } from "../../../reduxstore";
import { saveStoreReset } from "../../../reduxstore/reducers/store";

interface Props {
  existing?: Store;
  handleSubmitInfo: (
    name: string,
    type: string,
    intro: string,
    location: [number, number]
  ) => void;
}

interface FormData {
  name: string;
  type: string;
  intro: string;
  lat: string;
  lng: string;
}

const StepInfo = ({ handleSubmitInfo, existing }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const defaultValues = {
    ...existing,
    lat: existing ? existing.location.latitude : 0,
    lng: existing ? existing.location.longitude : 0,
  };

  useEffect(() => {
    dispatch(saveStoreReset());
  }, [dispatch]);

  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues,
  });

  const isComplete = () => {
    const values = watch();
    return (
      values.name &&
      values.name.length > 0 &&
      values.intro &&
      values.intro.length > 0 &&
      values.lat &&
      values.lng
    );
  };

  const onSubmit = (data: FormData) => {
    handleSubmitInfo(data.name, data.type, data.intro, [
      parseFloat(data.lat),
      parseFloat(data.lng),
    ]);
  };

  const handleLocationChange = (loc: any) => {
    setValue("lat", loc.lat);
    setValue("lng", loc.lng);
  };

  const storeTypes: Array<string> = ["small", "medium", "supermarket"];

  return (
    <div>
      <div className="store-form-header spaced">
        <LogoBig />
        <div className="store-form-title">{t(`store_form_info_title`)}</div>
        <div className="store-form-desc">{t(`store_form_info_desc`)}</div>
      </div>
      <form className="store-create-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="store-form-info spaced">
          <div className="store-form-info-row">
            <input
              autoComplete={"off"}
              ref={register}
              type="text"
              name="name"
              placeholder={t("store_form_input_name")}
              required={true}
            />
          </div>
          <div className="store-form-info-row">
            <select
              ref={register}
              name="type"
              placeholder={t("store_form_input_type")}
            >
              {storeTypes.map((st) => (
                <option key={st} value={st}>
                  {t("store_type_" + st)}
                </option>
              ))}
            </select>
          </div>
          <div className="store-form-info-row">
            <textarea
              ref={register}
              name="intro"
              placeholder={t("store_form_input_intro")}
            />
          </div>
          <input ref={register} type="hidden" name="lat" />
          <input ref={register} type="hidden" name="lng" />
        </div>
        <div>
          <LocationInput
            onChange={handleLocationChange}
            zoom={existing ? 18 : 10}
            lat={defaultValues.lat}
            lng={defaultValues.lng}
          />
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "1em",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Button label={t("go_to_next_step")} disabled={!isComplete()} />
        </div>
      </form>
    </div>
  );
};

export default StepInfo;
