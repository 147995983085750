import React, { useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import StepConfirm from "./StepConfirm";
import StepLogo from "./StepLogo";
import StepInfo from "./StepInfo";
import { URLS } from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../reduxstore";
import StoreService from "services/db/StoreService";
import { Store, StoreCreateData } from "../../../reduxstore/types";
import "./style.css";
import { createStoreAction } from "../../../reduxstore/reducers/store";

interface Props {
  existing?: Store;
}

const CreateStorePage = ({ existing }: Props) => {
  const history = useHistory();
  let { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const [store, setStore] = useState<StoreCreateData>(
    StoreService.getDefaultCreateData()
  );
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;

  const createOrEditURL = existing
    ? URLS.storeManager.editStore.replace(":id", existing.id || "")
    : URLS.storeManager.createStore;

  const handleSubmitInfo = (
    name: string,
    type: string,
    intro: string,
    location: [number, number]
  ) => {
    setStore({ ...store, name, type, intro, location });
    history.push(`${createOrEditURL}/logo`);
  };

  const handleSubmitLogo = (imageURL: string) => {
    setStore({ ...store, imageURL });
    history.push(`${createOrEditURL}/confirm`);
  };

  const redirectToStoreDashboard = (id: string) =>
    history.push(URLS.storeManager.storeDashboard.replace(":id", id));

  const handleConfirm = async () => {
    if (existing?.id) {
      await StoreService.updateStore(userId, existing.id, store);
      redirectToStoreDashboard(existing.id);
    } else {
      dispatch(createStoreAction(userId, store));
    }
  };

  return (
    <Switch>
      <Route exact path={`${path}/logo`}>
        <StepLogo handleSubmitLogo={handleSubmitLogo} existing={existing} />
      </Route>
      <Route exact path={`${path}/confirm`}>
        <StepConfirm
          handleConfirm={handleConfirm}
          goBackToFirstStep={() => history.push(createOrEditURL)}
          store={store}
        />
      </Route>
      <Route>
        <StepInfo handleSubmitInfo={handleSubmitInfo} existing={existing} />
      </Route>
    </Switch>
  );
};

export default CreateStorePage;
