import React from "react";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        width: "100%",
      }}
    >
      <img alt="" src="/icons/logo384x384.png" width={200} />
    </div>
  );
};

export default Loading;
