import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";

const fbConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(fbConfig);
let firebaseMessaging;
// try {
// firebaseMessaging = firebase.messaging();

// firebaseMessaging.usePublicVapidKey(
//   `${process.env.REACT_APP_MESSAGING_WEB_PUSH_KEY}`
// );
// } catch (e) {
// console.log('Some error occurred in GCM registration: ', e);
// }

export default firebase;
export { firebaseMessaging };
