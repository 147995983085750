import React from "react";
import { Helmet } from "react-helmet";
import { brand, URLS } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const title = brand.name + " - Error";
const description = brand.desc;

const Error = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <h3>{t("Error!")}</h3>
      <h5>
        {t("Oops, seems yo missed some bread crumbs to follow the path.")}
      </h5>
      <Link to={URLS.home}>{t("Start again from here")}</Link>
    </div>
  );
};

export default Error;
