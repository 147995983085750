import React from "react";

interface Props {
  src: string;
}

const Thumb = ({ src }: Props) => {
  return (
    <div
      style={{
        width: "4em",
        height: "4em",
        display: "flex",
        alignItems: "center",
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <img alt="" src={src} width="100%" />
    </div>
  );
};

export default Thumb;
