import { ReactComponent as Logo } from "./logo.svg";
import logo from "./logo.svg";
import React from "react";

export const LogoBig = () => {
  return <Logo style={{ width: 100 }} />;
};

export const LogoMenu = () => {
  return <img alt="" src={logo} style={{ width: 30, height: 20 }} />;
};
