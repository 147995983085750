/*
https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd
 */
import React, { ChangeEventHandler } from "react";
import "./style.css";

interface Props {
  checked: boolean;
  onChange: ChangeEventHandler;
}

const Checkbox = ({ checked, onChange }: Props) => (
  <div className="checkbox-container">
    <input
      type="checkbox"
      checked={checked}
      className="checkbox-input"
      onChange={onChange}
    />
    <div className="checkbox">
      <svg
        viewBox="0 0 24 24"
        className="checkbox-svg"
        style={{ visibility: checked ? "visible" : "hidden" }}
      >
        <polyline points="20 6 9 17 4 12" />
      </svg>
    </div>
  </div>
);

export default Checkbox;
