import React from "react";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import iconLogout from "assets/ic_exit_to_app_24px.svg";
import iconManageQueue from "assets/ic_manage_queue.svg";
import iconAddUser from "assets/ic_add_user.svg";
import iconEdit from "assets/ic_edit_pen.svg";
import iconHome from "assets/ic_settings_24px.svg";
import iconQR from "assets/ic_qr_code.svg";
import iconShare from "assets/ic_share_24px.svg";
import { ReactComponent as IconRightArrow } from "assets/ic_arrow.svg";
import "./style.css";
import { URLS } from "../../utils/constants";
import { StoreSummary } from "../../reduxstore/types";
import Thumb from "../Thumb";
import { useAppDispatch } from "../../reduxstore";
import { saveUserReset } from "../../reduxstore/reducers/user";

interface Props {
  onSelect: () => void;
  open: boolean;
  store?: StoreSummary;
  multipleStores?: boolean;
}

const DrawerMenu = ({ open, onSelect, store, multipleStores }: Props) => {
  const firebase = useFirebase();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleLogout = async () => {
    dispatch(saveUserReset());
    await firebase.logout();
  };

  const storeUrl = (path: string) => {
    return `${URLS.storeManager.storeDashboard.replace(
      ":id",
      store!.id
    )}${path}`;
  };

  const handlePageSelect = (url: string) => {
    history.push(url);
    onSelect();
  };

  const classes = [
    "no-print",
    "drawer-menu",
    open ? "drawer-menu-show" : "drawer-menu-hide",
  ];

  return (
    <nav className={classes.join(" ")}>
      {store && (
        <div className="drawer-store-summary">
          <Thumb src={store.imageURL} />
          <div style={{ marginTop: "1em" }}>{store.name}</div>
        </div>
      )}
      {multipleStores ? (
        <MenuItem
          icon={iconHome}
          label={t("my_stores")}
          onClick={() => history.push(URLS.storeManager.selectStore)}
        />
      ) : (
        <MenuItem
          icon={iconHome}
          label={t("add_new_store")}
          onClick={() => history.push(URLS.storeManager.createStore)}
        />
      )}
      {store && (
        <MenuItem
          icon={iconManageQueue}
          label={t("manage_queue")}
          onClick={() => handlePageSelect(storeUrl(""))}
        />
      )}
      {store && (
        <MenuItem
          icon={iconEdit}
          label={t("edit_store")}
          onClick={() => handlePageSelect(storeUrl("/edit"))}
        />
      )}
      {store && (
        <MenuItem
          icon={iconQR}
          label={t("print_poster")}
          onClick={() => handlePageSelect(storeUrl(`/qr`))}
        />
      )}
      {store && (
        <MenuItem
          icon={iconShare}
          label={t("share_store_link")}
          onClick={() => handlePageSelect(storeUrl(`/link`))}
        />
      )}
      {store && (
        <MenuItem
          icon={iconAddUser}
          label={t("add_user")}
          onClick={() => handlePageSelect(storeUrl("/invite"))}
        />
      )}
      <MenuItem icon={iconLogout} label={t("logout")} onClick={handleLogout} />
    </nav>
  );
};

interface MenuItemProps {
  icon: string;
  label: string;
  onClick: () => void;
}

const MenuItem = ({ icon, label, onClick }: MenuItemProps) => {
  return (
    <button className="menu-item" onClick={onClick}>
      <img alt="" src={icon} style={{ margin: "0 1em", width: "1.2em" }} />
      <span style={{ flexGrow: 1, paddingLeft: "1em", textAlign: "left" }}>
        {label}
      </span>
      <IconRightArrow />
    </button>
  );
};

export default DrawerMenu;
