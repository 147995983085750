import ReactCodeInput from "react-code-input";
import React from "react";

interface Props {
  onChange: (pin: string) => void;
  className: string;
  error: boolean;
}

const PinInput = ({ onChange, className, error }: Props) => {
  const borderColor = error ? "#d32f2f" : "#2196F3";
  const inputStyle: React.CSSProperties = {
    borderWidth: "0 0 2px 0",
    fontSize: 32,
    borderColor,
    padding: 0,
    margin: "0 3%",
    textAlign: "center",
    boxShadow: "none",
    WebkitBorderRadius: 0,
    WebkitAppearance: "none",
    width: "10%",
    transition: "all .3s",
  };

  return (
    <ReactCodeInput
      name="storeIdInput"
      type="number"
      fields={6}
      onChange={onChange}
      inputMode="numeric"
      inputStyle={inputStyle}
      className={className}
    />
  );
};

export default PinInput;
