import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReactComponent as ShareIcon } from "assets/ic_share_24px.svg";
import { URLS } from "../../../utils/constants";

interface Props {
  storeId: string;
}
const ShareAppLink = ({ storeId }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onShare = () => {
    if (navigator.share) {
      navigator.share({
        title: t("share_title"),
        text: t("share_text"),
        url: URLS.home,
      });
    } else {
      history.push(URLS.shopper.share.replace(":id", storeId));
    }
  };

  return (
    <div
      className="spaced"
      style={{
        marginBottom: "2em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ShareIcon />
      <div onClick={onShare} style={{ marginLeft: ".5em" }}>
        {t("share_qin_experience", { brand: "Q-in" })}
      </div>
    </div>
  );
};

export default ShareAppLink;
