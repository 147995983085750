import React from "react";
import { Switch, Route } from "react-router-dom";
import ShopperHomeScreen from "./Home";
import StoreDetails from "./Store";
import StoreShare from "./Share";
import NotFoundPage from "../Error/404";
import { URLS } from "utils/constants";
import { useAppSelector } from "../../reduxstore";
import Loading from "../../components/Loading";
import { useFirebase } from "react-redux-firebase";

const ShopperIndex = () => {
  const firebase = useFirebase();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);

  if (auth.isEmpty) {
    firebase.auth().signInAnonymously();
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path={URLS.shopper.home} component={ShopperHomeScreen} />
      <Route path={URLS.shopper.share} component={StoreShare} />
      <Route path={URLS.shopper.store} component={StoreDetails} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default ShopperIndex;
