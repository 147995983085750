import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "reduxstore";
import { AuthUserInfo, ProgressState, StoreManagerStore } from "../types";
import UserService from "../../services/db/UserService";

export interface StoreState {
  saveUserStatus: ProgressState;
  getUserStoresStatus: ProgressState;
  stores: Array<StoreManagerStore>;
}

const initialState: StoreState = {
  saveUserStatus: "idle",
  getUserStoresStatus: "idle",
  stores: [],
};

const reducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    getStoresInit(state) {
      state.getUserStoresStatus = "loading";
    },
    getStoresSuccess(state, action: PayloadAction<Array<StoreManagerStore>>) {
      state.getUserStoresStatus = "succeeded";
      state.stores = action.payload;
    },
    getStoresError(state) {
      state.getUserStoresStatus = "failed";
    },
    saveUserReset(state) {
      state.saveUserStatus = "idle";
    },
    saveUserInit(state) {
      state.saveUserStatus = "loading";
    },
    saveUserSuccess(state) {
      state.saveUserStatus = "succeeded";
    },
    saveUserError(state) {
      state.saveUserStatus = "failed";
    },
  },
});

const {
  saveUserInit,
  saveUserSuccess,
  saveUserError,
  getStoresInit,
  getStoresSuccess,
  getStoresError,
} = reducer.actions;

export const { saveUserReset } = reducer.actions;

export const getUserStoresAction = (userId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getStoresInit());
  try {
    const res = await UserService.fetchUserStores(userId);
    dispatch(getStoresSuccess(res));
  } catch (e) {
    console.log(`Failed to get user stores: ${e.message}`);
    dispatch(getStoresError());
  }
};

export const saveUserAction = (user: AuthUserInfo) => async (
  dispatch: AppDispatch
) => {
  dispatch(saveUserInit());
  try {
    await UserService.saveIfNotExists(user);
    dispatch(saveUserSuccess());
  } catch (e) {
    console.log(`Failed to save user: ${e.message}`);
    dispatch(saveUserError());
  }
};

export default reducer;
