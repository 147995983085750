import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout, { Bottom } from "components/FullHeightLayout";
import Button from "components/Button";
import { LogoBig } from "components/Logo";
import { URLS } from "utils/constants";
import PinInput from "./PinInput";
import iconCamera from "assets/ic_center_focus_weak_24px.svg";
import "./style.css";
import StoreService from "../../../services/db/StoreService";

type EntryMode = "pin" | "qr" | "none";

type StoreCheck = "idle" | "checking" | "found" | "notfound";

const ShopperHomeScreen = () => {
  const { t } = useTranslation();
  const [storeCheck, setStoreCheck] = useState<StoreCheck>("idle");
  const [entryMode, setEntryMode] = useState<EntryMode>("none");
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [storeId, setStoreId] = useState<string>("");

  if (storeId.length === 6 && storeCheck === "idle") {
    setStoreCheck("checking");
    StoreService.doesStoreExist(storeId)
      .then((exists) => setStoreCheck(exists ? "found" : "notfound"))
      .catch(() => setStoreCheck("notfound"));
  }

  if (storeId.length < 6 && storeCheck === "notfound") {
    setStoreCheck("idle");
  }

  if (storeCheck === "found") {
    return (
      <Redirect to={URLS.shopper.store.replace(":id", storeId)} push={true} />
    );
  }

  const onInputChange = (id: string) => {
    setStoreId(id);
    setStoreCheck("idle");
  };

  if (qrCode) {
    const urlArr = qrCode.split("/");
    const storeId = urlArr[urlArr.length - 1];
    return (
      <Redirect
        to={URLS.shopper.store.replace(":id", storeId.trim())}
        push={true}
      />
    );
  }

  const getBody = () => {
    if (entryMode === "qr") {
      return (
        <div className="shopper-home-qrreader-container">
          <QrReader
            className="shopper-home-qrreader"
            delay={300}
            onError={() => {}}
            onScan={setQrCode}
          />
        </div>
      );
    }
    return (
      <div className="spaced">
        <PinInput
          onChange={onInputChange}
          className="shopper-home-pin-container"
          error={storeCheck === "notfound"}
        />
        {storeCheck === "notfound" && (
          <div className="shopper-home-pin-error spaced">
            {t("shopper_no_store_with_given_id")}
          </div>
        )}
      </div>
    );
  };

  const getPlaceholder = () => {
    if (entryMode === "qr") return null;

    return (
      <div>
        <div className="shopper-home-qrreader-placeholder">
          <div className="shopper-home-qrreader-placeholder-l1">
            <div className="shopper-home-qrreader-placeholder-l1 shopper-home-qrreader-placeholder-l2">
              <img alt="" src={iconCamera} />
            </div>
          </div>
        </div>
        <div className="shopper-home-qrreader-desc">
          {t("open_qrreader_desc")}
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="shopper-home-top-container spaced">
        <div className="shopper-home-logo">
          <LogoBig />
        </div>
        <div className="shopper-home-title">{t("shopper_home_title")}</div>
        <div className="shopper-home-desc">{t("shopper_home_desc")}</div>
      </div>
      <div className="shopper-home-body-container">{getBody()}</div>
      <Bottom>
        {getPlaceholder()}
        {entryMode === "qr" ? (
          <Button
            label={t("close_qrreader")}
            onClick={() => setEntryMode("none")}
          />
        ) : (
          <Button
            label={t("open_qrreader")}
            onClick={() => setEntryMode("qr")}
          />
        )}
      </Bottom>
    </Layout>
  );
};

export default ShopperHomeScreen;
