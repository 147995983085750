import Layout from "../../../components/FullHeightLayout";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import Button from "../../../components/Button";
import SMHeader from "../../../components/SMHeader";

interface Props {
  storeId: string;
  message: string;
  url?: string;
  btnLabel?: string;
}

const StoreManagerError = ({ message, url, btnLabel, storeId }: Props) => {
  return (
    <Layout>
      <SMHeader currentStoreId={storeId} />
      <div className="error-container">
        <span className="error-text">{message}</span>
        {url && (
          <Link to={url}>
            <Button label={btnLabel!} />
          </Link>
        )}
      </div>
    </Layout>
  );
};

export default StoreManagerError;
