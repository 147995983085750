import {
  StoreAdminRole,
  StoreCreateData,
  StoreSummary,
} from "reduxstore/types";
import { DBCOLLECTIONS, DBCONSTANTS } from "api/services/dbConstants";
import firebaseApp from "api/firebase.config";
import { BaseService } from "./BaseService";
import { FB_EVENT_NAMES } from "../../utils/constants";
import QueueService from "./QueueService";

class StoreService extends BaseService {
  getDefaultCreateData(): StoreCreateData {
    return {
      name: "",
      imageURL: "",
      intro: "",
      type: "small",
      location: [0, 0],
    };
  }

  getNewStoreId(): Promise<string> {
    const coll = this.firestore.collection(DBCOLLECTIONS.STORE_NEXT_ID);
    const docRef = coll.doc(DBCONSTANTS.DOC_IDS.STORE_CODE);

    return coll.firestore.runTransaction((transaction) => {
      return transaction.get(docRef).then((doc) => {
        const existing_token_no = doc.data()!["code"];
        transaction.update(docRef, { code: existing_token_no + 1 });
        return existing_token_no + "";
      });
    });
  }

  async createStore(userId: string, post: StoreCreateData) {
    const id = await this.getNewStoreId();
    const data: firebaseApp.firestore.DocumentData = {
      ...post,
      location: new firebaseApp.firestore.GeoPoint(
        post.location[0],
        post.location[1]
      ),
      createdBy: userId,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };

    try {
      await this.firestore.collection(DBCOLLECTIONS.STORES).doc(id).set(data);
      await this.addUserToStore(id, userId, "owner");
      await this.createDefaultQueueInStore(userId, id);
      await this.analyticsService.storeCreated(id);
      return id;
    } catch (error) {
      await this.analyticsService.log(FB_EVENT_NAMES.storeCreationFailed, {
        data,
        error,
      });
      throw error;
    }
  }

  async updateStore(userId: string, storeId: string, post: StoreCreateData) {
    const data: firebaseApp.firestore.DocumentData = {
      ...post,
      location: new firebaseApp.firestore.GeoPoint(
        post.location[0],
        post.location[1]
      ),
      updatedAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    };
    await this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId)
      .set(data, { merge: true });
  }

  async addUserToStore(storeId: string, userId: string, role: StoreAdminRole) {
    await this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId)
      .collection(DBCOLLECTIONS.USERS)
      .doc(userId)
      .set({ role });

    await this.firestore
      .collection(DBCOLLECTIONS.USERS)
      .doc(userId)
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId)
      .set({ role });
  }

  async deleteStore(userId: string, storeId: string) {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId);
    const storeUsers = await storeRef.collection(DBCOLLECTIONS.USERS).get();
    await Promise.all(
      storeUsers.docs.map((storeUser) =>
        this.firestore
          .collection(DBCOLLECTIONS.STORES)
          .doc(storeId)
          .collection(DBCOLLECTIONS.USERS)
          .doc(storeUser.id)
          .delete()
      )
    );
    await Promise.all(
      storeUsers.docs.map((storeUser) =>
        this.firestore
          .collection(DBCOLLECTIONS.USERS)
          .doc(storeUser.id)
          .collection(DBCOLLECTIONS.STORES)
          .doc(storeId)
          .delete()
      )
    );
    await storeRef.delete();
  }

  async fetchStores(ids: Array<string>) {
    const refs = ids.map((id) =>
      this.firestore.collection(DBCOLLECTIONS.STORES).doc(id).get()
    );
    return Promise.all(refs).then((docs) => {
      const res: Array<StoreSummary> = [];
      docs.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data()!;
          const store = {
            id: doc.id,
            name: data["name"],
            imageURL: data["imageURL"],
          };
          res.push(store);
        }
      });
      return res;
    });
  }

  async doesStoreExist(id: string) {
    const maybeStore = await this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(id)
      .get();
    return maybeStore.exists;
  }

  async fetchShoppersInQueue(storeId: string, queueId: string) {
    const snapshot = await this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId)
      .collection("queues")
      .doc(queueId)
      .collection("shoppers")
      .get();

    return snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        ticketNo: data["ticketNo"],
        status: data["status"],
      };
    });
  }

  getQueue(storeId: string, queueId: string): QueueService {
    return new QueueService(this.firestore, storeId, queueId);
  }

  async createDefaultQueueInStore(userId: string, storeId: string) {
    await this.addQueueToStore(userId, storeId, "Default");
  }

  async addQueueToStore(userId: string, storeId: string, name: string) {
    const coll = this.firestore.collection(DBCOLLECTIONS.STORES);
    const storeRef = coll.doc(storeId);

    const queueRef = storeRef.collection("queues").doc();
    const queueData = {
      name,
      waitingTime: 2,
      nextTicketNo: 1,
      waitingTickets: [],
      isActive: false,
      isTicketingOpen: false,
    };

    return coll.firestore.runTransaction(async (transaction) => {
      await transaction.set(queueRef, queueData);
      await transaction.set(
        storeRef,
        { activeQueueId: queueRef.id },
        { merge: true }
      );
      return queueRef.id;
    });
  }
}

export default new StoreService(firebaseApp.firestore());
