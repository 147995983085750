import React from "react";
import { Store } from "reduxstore/types";
import "./style.css";
import Thumb from "../../../../components/Thumb";

interface ShopSummaryProps {
  store: Store;
}

const ShopSummary = ({ store }: ShopSummaryProps) => {
  return (
    <div className="shopper-store-summary-container">
      <div className="shopper-store-summary spaced">
        <div className="shopper-store-summary-desc">
          <div className="shopper-store-summary-desc-title">{store.name}</div>
          <div>{store.intro}</div>
        </div>
        <div className="shopper-store-summary-image">
          <Thumb src={store.imageURL} />
        </div>
      </div>
    </div>
  );
};

export default ShopSummary;
