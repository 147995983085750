import React from "react";
import { Redirect } from "react-router-dom";
import { URLS } from "../../../utils/constants";
import { MapOf, StoreSummary } from "../../../reduxstore/types";
import "./style.css";
import { useAppSelector } from "../../../reduxstore";

const StoreManagerHome = () => {
  const storeList = useAppSelector<MapOf<StoreSummary>>(
    (state) => state.store.stores
  );
  const storeIds = Object.keys(storeList);

  if (storeIds.length >= 1) {
    const url = URLS.storeManager.storeDashboard.replace(":id", storeIds[0]);
    return <Redirect to={url} />;
  }

  return <Redirect to={URLS.storeManager.createStore} />;
};

export default StoreManagerHome;
