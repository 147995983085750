import React from "react";
import { useTranslation } from "react-i18next";
import iconPeople from "assets/ic_people_24px.svg";
import iconSchedule from "assets/ic_schedule_24px.svg";
import { Queue, Shopper } from "reduxstore/types";
import StoreService from "services/db/StoreService";
import StaticMessage from "./StaticMessage";
import "./style.css";

interface QueueSummaryProps {
  storeId: string;
  queue: Queue;
  userInQueue?: Shopper;
}

const QueueSummary = ({ storeId, queue, userInQueue }: QueueSummaryProps) => {
  const { t } = useTranslation();
  const queueService = StoreService.getQueue(storeId, queue.id);
  const compareTicketNo =
    userInQueue && queueService.isStillWaiting(userInQueue.status)
      ? userInQueue.ticketNo
      : 9999999;
  const customersAhead = queue.waitingTickets.filter(
    (tn) => tn < compareTicketNo
  ).length;
  const estimatedTime = customersAhead * queue.waitingTime;

  if (
    userInQueue &&
    (userInQueue.status === "to_approve" || userInQueue.status === "approved")
  ) {
    return (
      <StaticMessage
        title={t("soon_its_your_turn")}
        subtitle={t("go_to_enterance")}
      />
    );
  }

  if (userInQueue && userInQueue.status === "let_in") {
    return (
      <StaticMessage
        title={t("please")}
        subtitle={t("enter_now")}
        subtitleBig={true}
      />
    );
  }

  if (userInQueue && userInQueue.status === "in_store") {
    return (
      <StaticMessage
        title={t("enjoy_your_shopping")}
        subtitle={t("let_us_know_when_you_leave")}
      />
    );
  }

  return (
    <div className="shopper-queue-summary-container">
      <div className="shopper-queue-summary">
        <div className="shopper-queue-summary-col">
          <div className="shopper-queue-summary-text--big">
            <img alt="" src={iconPeople} />
          </div>
          <div className="shopper-queue-summary-text--small">
            {t("customers_ahead")}
          </div>
          <div className="shopper-queue-summary-text--big">
            {customersAhead}
          </div>
        </div>
        <div className="shopper-queue-summary-col">
          <div className="shopper-queue-summary-text--big">
            <img alt="" src={iconSchedule} />
          </div>
          <div className="shopper-queue-summary-text--small">
            {t("est_waiting_time")}
          </div>
          <div className="shopper-queue-summary-text--big">
            {estimatedTime} {t("minutes_short")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueSummary;
