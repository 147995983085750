import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Layout, { Bottom } from "components/FullHeightLayout";
import Button from "components/Button";
import { LogoBig } from "components/Logo";
import { URLS } from "utils/constants";
import "./style.css";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../reduxstore";
import Loading from "../../../components/Loading";
import { fetchStoresAction } from "../../../reduxstore/reducers/store";
import { ReactComponent as FacebookIcon } from "assets/ic_social_facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/ic_social_twitter.svg";

const ShopperShareScreen = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const storeLoadStatus = useAppSelector(
    (state) => state.store.fetchStoresStatus
  );
  const loadingCompleted = storeLoadStatus === "succeeded";
  const store = useAppSelector((state) => state.store.stores[id]);

  useEffect(() => {
    dispatch(fetchStoresAction([id]));
  }, [dispatch, id]);

  if (!loadingCompleted) {
    return <Loading />;
  }

  const url = `${window.location.origin}${URLS.home}`;
  const shareText = `${store.name}, ${t("share-text")}`;

  const twitterShareUrl = `https://twitter.com/share?url=${url}&text=${encodeURIComponent(
    shareText
  )}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&text=${encodeURIComponent(
    shareText
  )}`;

  return (
    <Layout>
      <div className="shopper-home-top-container spaced">
        <div className="shopper-home-logo">
          <LogoBig />
        </div>
        <div className="shopper-home-title">{t("shopper_home_title")}</div>
        <div className="shopper-home-desc">{t("shopper_home_desc")}</div>
      </div>
      <div className="shopper-share-container">
        <div className="shopper-share-item">
          <a
            href={twitterShareUrl}
            target="_blank"
            rel="noreferrer noopener"
            className="share-button share-button-twitter"
          >
            <TwitterIcon className="share-button-icon" />
            <div className="share-button-text">{t("share_on_twitter")}</div>
          </a>
        </div>
        <div className="shopper-share-item">
          <a
            href={facebookUrl}
            target="_blank"
            rel="noreferrer noopener"
            className="share-button share-button-facebook"
          >
            <FacebookIcon className="share-button-icon" />
            <div className="share-button-text">{t("share_on_facebook")}</div>
          </a>
        </div>
      </div>
      <Bottom>
        <Button
          color="secondary"
          label={t("back")}
          onClick={() => history.push(URLS.shopper.store.replace(":id", id))}
        />
      </Bottom>
    </Layout>
  );
};

export default ShopperShareScreen;
