export const brand = {
  name: "Q-in",
  desc: "Q-in - A simple but efficient getQueue management system",
  prefix: "qin",
  footerText: `© - All Rights Reserved ${new Date().getFullYear()}`,
  logoText: "Q-in",
};

export const BASE_APP_URL = "https://q-in.app";
export const URLS = {
  home: "/",
  signin: "/signin",
  contact: "/contact",
  shopper: {
    root: "/",
    home: "/",
    store: "/store/:id",
    share: "/store/:id/share",
  },
  storeManager: {
    root: "/store-manager",
    selectStore: "/store-manager/stores",
    createStore: "/store-manager/store/new",
    editStore: "/store-manager/store/:id/edit",
    storeDashboard: "/store-manager/store/:id",
    doorman: "/store-manager/store/:id/queue/:qid",
    addUserToQueue: "/store-manager/store/:id/queue/:qid/user",
  },
  auth: "/auth",
  authWithToken: "/auth/:token",
  pageNotFound: "/404",
  internalServerError: "/500",
};

export const FB_EVENT_NAMES = {
  storeCreated: "STORE_CREATED",
  storeCreationFailed: "STORE_CREATION_FAILED",
  addedToQueue: "ADDED_TO_QUEUE",
  leaveQueue: "LEAVE_QUEUE",
  resetQueue: "RESET_QUEUE",
  exitFromStore: "EXIT_FROM_STORE",
  enteredTheStore: "ENTER_THE_STORE",
  removedFromTheQueue: "REMOVED_FROM_THE_QUEUE",
  imageUploaded: "IMAGE_UPLOADED",
  storeCodeIncremented: "STORE_CODE_INCREMENTED",
};
