import React from "react";
import "./style.css";
import Spinner from "../Spinner";

type ButtonShape = "rounded" | "flat";
type ButtonColor = "primary" | "secondary";

interface IconButtonProps {
  onClick: () => void;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const IconButton = ({ Icon, onClick }: IconButtonProps) => {
  return (
    <button className="icon-button" onClick={onClick}>
      <Icon />
    </button>
  );
};

interface Props {
  label: string | JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button";
  loading?: boolean;
  shape?: ButtonShape;
  color?: ButtonColor;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Button = ({
  label,
  onClick,
  disabled = false,
  loading = false,
  shape = "rounded",
  color = "primary",
  Icon,
  type = "submit",
}: Props) => {
  const classNames = [
    `button`,
    `button-shape-${shape}`,
    disabled ? "button--disabled" : `button-color-${color}`,
  ];

  const getLabel = () => {
    const getIcon = () => {
      if (Icon) return <Icon />;
      if (loading) return <Spinner white={true} small={true} />;
      return null;
    };
    const icon = getIcon();

    if (icon) {
      return (
        <div className="button-icon-container">
          {icon}
          {label}
        </div>
      );
    }

    return label;
  };

  const handleClick = () => {
    if (!disabled && !loading && onClick) {
      onClick();
    }
  };

  return (
    <button className={classNames.join(" ")} onClick={handleClick} type={type}>
      {getLabel()}
    </button>
  );
};

export default Button;
