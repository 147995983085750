import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import StoreManagerHome from "./Home";
import { URLS } from "utils/constants";
import { useAppDispatch, useAppSelector } from "../../reduxstore";
import Loading from "components/Loading";
import "./index.css";
import CreateStorePage from "./CreateStore";
import StoreManagerStoreIndex from "./Store";
import StoreManagerSelectStore from "./SelectStore";
import { getUserStoresAction } from "../../reduxstore/reducers/user";
import SMHeader from "../../components/SMHeader";
import { useTranslation } from "react-i18next";
import { fetchStoresAction } from "../../reduxstore/reducers/store";

const StoreManagerIndex = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userStoresLoadStatus = useAppSelector(
    (state) => state.user.getUserStoresStatus
  );
  const storeLoadStatus = useAppSelector(
    (state) => state.store.fetchStoresStatus
  );
  const userStoresLoaded =
    userStoresLoadStatus === "succeeded" || userStoresLoadStatus === "failed";
  const storesLoaded =
    storeLoadStatus === "succeeded" || storeLoadStatus === "failed";
  const userStores = useAppSelector((state) => state.user.stores);

  useEffect(() => {
    if (userStoresLoadStatus === "idle" && auth.uid) {
      dispatch(getUserStoresAction(auth.uid));
    }
  }, [dispatch, auth.uid, userStoresLoadStatus]);

  useEffect(() => {
    if (userStores.length > 0) {
      const ids = userStores.map((s) => s.id);
      dispatch(fetchStoresAction(ids));
    }
  }, [dispatch, userStores]);

  if (auth.isEmpty || auth.isAnonymous) {
    return <Redirect to={{ pathname: URLS.auth }} />;
  }

  if (!userStoresLoaded || (userStores.length > 0 && !storesLoaded)) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route exact path={URLS.storeManager.root}>
        <StoreManagerHome />
      </Route>
      <Route exact path={URLS.storeManager.selectStore}>
        <StoreManagerSelectStore />
      </Route>
      <Route path={URLS.storeManager.createStore}>
        <div>
          <SMHeader title={t("create_store")} />
          <CreateStorePage />
        </div>
      </Route>
      <Route path={URLS.storeManager.storeDashboard}>
        <StoreManagerStoreIndex />
      </Route>
    </Switch>
  );
};

export default StoreManagerIndex;
