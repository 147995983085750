import { createSlice } from "@reduxjs/toolkit";

export interface StoreState {
  updateAvailable: boolean;
  registration: any;
}

const initialState: StoreState = {
  updateAvailable: false,
  registration: null,
};

const reducer = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUpdateAvailable(state, action) {
      state.updateAvailable = true;
      state.registration = action.payload;
    },
    ignoreUpdate(state) {
      state.updateAvailable = false;
    },
  },
});

export const { setUpdateAvailable, ignoreUpdate } = reducer.actions;

export default reducer;
