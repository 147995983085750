import React, { useRef, useState } from "react";
import { SendStatus, Store } from "reduxstore/types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import SMTitle from "components/SMTitle";
import TokenService from "services/db/TokenService";
import NotificationService from "services/db/NotificationService";
import { BASE_APP_URL, URLS } from "utils/constants";
import "./style.css";
import { validateEmail } from "../../../../utils/helpers";
import FeedbackText from "../../../../components/FeedbackText";
import Layout from "../../../../components/FullHeightLayout";
import SMHeader from "../../../../components/SMHeader";

interface Props {
  store: Store;
}

interface FormData {
  email: string;
}

const AddUserPage = ({ store }: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const [sendStatus, setSendStatus] = useState<SendStatus>("idle");
  const [url, setUrl] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const onSubmit = async (data: FormData) => {
    if (data.email.length === 0 || !validateEmail(data.email)) {
      setSendStatus("invalid");
    } else {
      setSendStatus("sending");
      try {
        const tokenId = await TokenService.create(store.id, "storeInvitation", {
          storeId: store.id,
          role: "doorman",
        });
        const url = `${BASE_APP_URL}${URLS.authWithToken.replace(
          ":token",
          `${store.id}-${tokenId}`
        )}`;
        setUrl(url);
        const email = {
          to: data.email,
          subject: t("email_store_manager_invite_subject"),
          body: t("email_store_manager_invite_body", {
            url,
          }),
        };

        await NotificationService.email(store.id, email);
        setSendStatus("succeeded");
      } catch (e) {
        console.error(e);
        setSendStatus("failed");
      }
    }
  };

  const copyToClipboard = () => {
    textAreaRef.current?.select();
    textAreaRef.current?.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setCopied(true);
  };

  const resetState = () => {
    setSendStatus("idle");
    setCopied(false);
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="sm-store-invite">
        <form onSubmit={handleSubmit(onSubmit)}>
          <SMTitle
            title={t("send_invitation_title")}
            subtitle={t("send_invitation_desc")}
          />
          <div className="sm-store-invite-input spaced">
            <input
              ref={register}
              type="text"
              name="email"
              placeholder={t("add_user_email_placeholder")}
              required={true}
              autoComplete="off"
              onChange={resetState}
            />
          </div>
          {sendStatus === "failed" && (
            <FeedbackText message={t("add_user_error_failed")} error={true} />
          )}
          {sendStatus === "invalid" && (
            <FeedbackText
              message={t("add_user_error_invalid_email")}
              error={true}
            />
          )}
          {sendStatus === "succeeded" && (
            <div>
              {copied ? (
                <FeedbackText
                  success={true}
                  message={t("add_user_copy_success")}
                />
              ) : (
                <FeedbackText
                  message={t("add_user_email_sent")}
                  success={true}
                />
              )}
              <div className="spaced" style={{ textAlign: "center" }}>
                <textarea
                  ref={textAreaRef}
                  className="sm-store-invite-textarea"
                  defaultValue={url}
                  readOnly={true}
                />
                <Button
                  color={"secondary"}
                  label={t("add_user_copy_link")}
                  type="button"
                  onClick={copyToClipboard}
                />
              </div>
            </div>
          )}
          {sendStatus === "sending" && <FeedbackText loading={true} />}
          {sendStatus === "idle" && (
            <div className="sm-store-invite-bottom">
              <Button label={t("send_invitation_btn")} />
            </div>
          )}
        </form>
      </div>
    </Layout>
  );
};

export default AddUserPage;
