import { Queue, Shopper, ShopperQueueState } from "reduxstore/types";
import { DBCOLLECTIONS } from "api/services/dbConstants";
import firebaseApp from "api/firebase.config";
import { BaseService } from "./BaseService";
import { FB_EVENT_NAMES } from "../../utils/constants";
import { notifyUserCount } from "../../config/configs";

interface UserJoinsQueueParams {
  userId: string;
  phoneNo?: string;
}

export interface NotifyUserInfo {
  ticketNo: number;
  phoneNo: string;
}

class QueueService extends BaseService {
  storeId: string;
  queueId: string;

  inQueueStatusList: Array<ShopperQueueState> = [
    "waiting",
    "to_approve",
    "approved",
  ];
  afterLetInStatusList: Array<ShopperQueueState> = [
    "let_in",
    "in_store",
    "done",
  ];
  static canJoinQueueStatusList: Array<ShopperQueueState> = [
    "left",
    "removed",
    "done",
  ];

  constructor(
    firestore: firebaseApp.firestore.Firestore,
    storeId: string,
    queueId: string
  ) {
    super(firestore);
    this.storeId = storeId;
    this.queueId = queueId;
  }

  async waitingCount(): Promise<number> {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId);
    const queueRef = storeRef.collection("queues").doc(this.queueId);
    const shoppers = await queueRef
      .collection("shoppers")
      .where("status", "in", this.inQueueStatusList)
      .get();
    return shoppers.size;
  }

  async getWaitingTime() {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId);
    const queueRef = storeRef.collection("queues").doc(this.queueId);
    const queue = await queueRef.get();
    return queue.data()!["waitingTime"];
  }

  async setWaitingTime(waitingTime: number) {
    return await this.update({ waitingTime });
  }

  wasLetIn(status: ShopperQueueState) {
    return this.afterLetInStatusList.indexOf(status) >= 0;
  }

  isStillWaiting(status: ShopperQueueState) {
    return this.inQueueStatusList.indexOf(status) >= 0;
  }

  async doormanNext(ticketNo: number) {
    const toBeNotified = await this.doormanUpdateQueue(ticketNo);
    await this.updateShopperWithTicket(ticketNo, "let_in");
    const toBeSMSed = await this.notifyUsers(toBeNotified);
    await this.analyticsService.log(FB_EVENT_NAMES.enteredTheStore, {
      storeId: this.storeId,
    });

    return toBeSMSed;
  }

  async doormanRemove(ticketNo: number) {
    const toBeNotified = await this.doormanUpdateQueue(ticketNo);
    await this.updateShopperWithTicket(ticketNo, "removed");
    const toBeSMSed = await this.notifyUsers(toBeNotified);
    await this.analyticsService.log(FB_EVENT_NAMES.removedFromTheQueue, {
      storeId: this.storeId,
    });

    return toBeSMSed;
  }

  async doormanAddsUser(sanitizedPhone: string) {
    return await this.userJoinsQueue({
      userId: sanitizedPhone,
      phoneNo: sanitizedPhone,
    });
  }

  async userGoesIn(userId: string) {
    await this.updateUserQueueStatus(userId, "in_store");
  }

  async userIsDone(userId: string) {
    await this.updateUserQueueStatus(userId, "done");
  }

  async userApprovesAvailability(userId: string) {
    await this.updateUserQueueStatus(userId, "approved");
  }

  async userLeavesQueue(userId: string) {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId);
    const queueRef = storeRef.collection("queues").doc(this.queueId);
    const shopperRef = queueRef.collection("shoppers").doc(userId);

    await this.firestore.runTransaction(async (transaction) => {
      const shopper = await transaction.get(shopperRef);
      await transaction.update(shopperRef, { status: "left" });
      await transaction.update(queueRef, {
        waitingTickets: firebaseApp.firestore.FieldValue.arrayRemove(
          shopper.data()!["ticketNo"]
        ),
      });
    });
    await this.analyticsService.log(FB_EVENT_NAMES.leaveQueue, {
      storeId: this.storeId,
    });
  }

  async updateUserQueueStatus(userId: string, status: ShopperQueueState) {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId);
    const queueRef = storeRef.collection("queues").doc(this.queueId);
    const shopperRef = queueRef.collection("shoppers").doc(userId);
    const data: Partial<firebaseApp.firestore.DocumentData> = { status };
    if (status === "approved") {
      data.approvedAt = firebaseApp.firestore.FieldValue.serverTimestamp();
    }
    await shopperRef.set(data, { merge: true });
  }

  async userJoinsQueue({ userId, phoneNo }: UserJoinsQueueParams) {
    const storeRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId);
    const queueRef = storeRef.collection("queues").doc(this.queueId);
    const shopperRef = queueRef.collection("shoppers").doc(userId);
    const newShopperRef = queueRef.collection("shoppers").doc();
    const now = firebaseApp.firestore.FieldValue.serverTimestamp();

    return this.firestore.runTransaction(async (transaction) => {
      const queue = await transaction.get(queueRef);
      const shopper = await transaction.get(shopperRef);

      if (shopper.exists) {
        // Replace the existing previous document with a random ID to allow another record for the same user
        const data = shopper.data();
        await transaction.delete(shopperRef);
        await transaction.set(newShopperRef, { ...data, userId });
      }
      const queueData = queue.data()!;
      const status =
        queueData["waitingTickets"].length === 0 ? "to_approve" : "waiting";
      const ticketNo = queueData["nextTicketNo"];
      await transaction.update(queueRef, {
        nextTicketNo: ticketNo + 1,
        waitingTickets: firebaseApp.firestore.FieldValue.arrayUnion(ticketNo),
      });
      const shopperData: Partial<firebaseApp.firestore.DocumentData> = {
        ticketNo,
        queuedAt: now,
        status,
      };
      if (phoneNo) {
        shopperData.phoneNo = phoneNo;
      }
      if (status === "to_approve") {
        shopperData.notifiedAt = now;
      }
      await transaction.set(shopperRef, shopperData);

      await this.analyticsService.log(FB_EVENT_NAMES.addedToQueue, {
        storeId: this.storeId,
      });

      return ticketNo;
    });
  }

  static canJoinQueue(userInQueue?: Shopper): boolean {
    return (
      !userInQueue ||
      QueueService.canJoinQueueStatusList.indexOf(userInQueue.status) >= 0
    );
  }

  static isQueueAvailable(queue: Queue): boolean {
    return queue.isActive && queue.isTicketingOpen;
  }

  private async updateShopperWithTicket(
    ticketNo: number,
    status: ShopperQueueState
  ) {
    const queueRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId)
      .collection("queues")
      .doc(this.queueId);

    const shopperListRef = await queueRef
      .collection("shoppers")
      .where("ticketNo", "==", ticketNo)
      .get();

    if (!shopperListRef.empty) {
      const shopperRef = shopperListRef.docs[0].ref;
      await shopperRef.set({ status }, { merge: true });
    }
  }

  private async notifyUsers(
    ticketNumbers: number[]
  ): Promise<NotifyUserInfo[]> {
    if (ticketNumbers.length === 0) {
      return Promise.resolve([]);
    }

    const queueRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId)
      .collection("queues")
      .doc(this.queueId);

    const docs = await queueRef
      .collection("shoppers")
      .where("ticketNo", "in", ticketNumbers)
      .get();

    const now = firebaseApp.firestore.FieldValue.serverTimestamp();
    const promises = docs.docs.map((doc) => {
      return this.firestore.runTransaction(async (transaction) => {
        const data = doc.data() as Shopper;
        if (data.status === "waiting") {
          transaction.update(doc.ref, {
            status: "to_approve",
            notifiedAt: now,
          });
          if (data.phoneNo) {
            return {
              phoneNo: data.phoneNo,
              ticketNo: data.ticketNo,
            };
          }
        }
      });
    });
    return (await Promise.all(promises)).filter(
      (id) => id !== undefined
    ) as NotifyUserInfo[];
  }

  private doormanUpdateQueue(ticketNo: number) {
    const queueRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId)
      .collection("queues")
      .doc(this.queueId);

    return this.firestore.runTransaction(async (transaction) => {
      const queue = await transaction.get(queueRef);
      const waitingTickets = queue.data()!["waitingTickets"];
      if (waitingTickets.indexOf(ticketNo) === 0) {
        await transaction.update(queueRef, {
          waitingTickets: firebaseApp.firestore.FieldValue.arrayRemove(
            ticketNo
          ),
        });
        return waitingTickets.slice(1, notifyUserCount + 1);
      }
      return [];
    });
  }

  async setTicketing(isTicketingOpen: boolean) {
    return await this.update({ isTicketingOpen });
  }

  async setActive(isActive: boolean) {
    return await this.update({ isActive });
  }

  async update(data: firebaseApp.firestore.DocumentData) {
    const queueRef = this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(this.storeId)
      .collection("queues")
      .doc(this.queueId);

    await queueRef.set(data, { merge: true });
  }
}

export default QueueService;
