import React, { useState } from "react";
import cx from "classnames";
// import { ReactComponent as IconUp } from "assets/ic_up.svg";
// import { ReactComponent as IconDown } from "assets/ic_down.svg";
import iconAlarmOff from "../../../../assets/ic_alarm_off_24px.svg";
import iconAlarmOn from "../../../../assets/ic_alarm_on_24px.svg";
import iconPause from "../../../../assets/ic_pause_circle_outline_24px.svg";
import iconStart from "../../../../assets/ic_play_circle_outline_24px.svg";
import iconDelete from "../../../../assets/ic_delete_24px.svg";
import iconTimer from "../../../../assets/timer.svg";
import { Queue } from "../../../../reduxstore/types";
import StoreService from "../../../../services/db/StoreService";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
// import Button, { IconButton } from "../../../../components/Button";
import { URLS } from "../../../../utils/constants";
import { useAppSelector } from "../../../../reduxstore";
import BlockArea from "../../../../components/BlockArea";
import Modal from "../../../../components/Modal";

interface Props {
  storeId: string;
  queue: Queue;
  initiallyExpanded?: boolean;
}

const QueueRow = ({ storeId, queue, initiallyExpanded }: Props) => {
  // const [expanded, setExpanded] = useState<boolean>(!!initiallyExpanded);
  const expanded = initiallyExpanded;
  const { t } = useTranslation();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;
  const [progress, setProgress] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [waitingTime, setWaitingTime] = useState<string>(
    `${queue.waitingTime}`
  );
  const history = useHistory();

  const genericProp = (check: boolean, ok_label: string, nok_label: string) => {
    return (
      <span
        className={cx([
          "sm-queue-item-prop",
          check ? "sm-queue-item-prop-green" : "sm-queue-item-prop-red",
        ])}
      >
        {check ? t(ok_label) : t(nok_label)}
      </span>
    );
  };

  const activeProp = (q: Queue) =>
    genericProp(q.isActive, "queue_active", "queue_inactive");
  const ticketProp = (q: Queue) =>
    genericProp(
      q.isTicketingOpen,
      "queue_ticketing_open",
      "queue_ticketing_closed"
    );

  const handleSetActivateQueue = async (isActive: boolean) => {
    const dbQueue = StoreService.getQueue(storeId, queue.id);
    await dbQueue.setActive(isActive);
  };

  const handleSetQueueTicketing = async (isActive: boolean) => {
    const dbQueue = StoreService.getQueue(storeId, queue.id);
    await dbQueue.setTicketing(isActive);
  };

  const handleResetQueue = async () => {
    setProgress(true);
    await StoreService.createDefaultQueueInStore(userId, storeId);
    setProgress(false);
  };

  const handleSetQueueingTime = async () => {
    setShowModal(true);
  };

  const handleSaveQueueingTime = async () => {
    const t = parseInt(waitingTime);
    if (t) {
      const dbQueue = StoreService.getQueue(storeId, queue.id);
      await dbQueue.setWaitingTime(t);
      setShowModal(false);
    } else {
      handleCancelQueueingTime();
    }
  };

  const handleCancelQueueingTime = () => {
    setWaitingTime(`${queue.waitingTime}`);
    setShowModal(false);
  };

  return (
    <div className="sm-queue-item">
      <Modal displayed={showModal} onDismiss={handleCancelQueueingTime}>
        <div className="sm-queue-waiting-time-title">
          {t("set_queueing_time")}
        </div>
        <div className="sm-queue-waiting-time">
          <span>
            <input
              className="sm-queue-waiting-time-input"
              type="number"
              value={waitingTime}
              onChange={(e) => setWaitingTime(e.target.value)}
              size={3}
            />
            <span style={{ marginLeft: 4 }}>{t("minutes_short")}</span>
          </span>
          <button
            className="sm-queue-waiting-time-btn"
            onClick={handleSaveQueueingTime}
          >
            {t("set")}
          </button>
        </div>
      </Modal>
      <div className="sm-queue-item-row">
        <div className={cx("sm-queue-item-name-container")}>
          <div className="sm-queue-item-name">{queue.name}</div>
          {activeProp(queue)}
          {ticketProp(queue)}
        </div>
        {/*expanded ? (
          <IconButton Icon={IconUp} onClick={() => setExpanded(false)} />
        ) : (
          <IconButton Icon={IconDown} onClick={() => setExpanded(true)} />
        )*/}
      </div>
      {expanded && (
        <BlockArea active={progress}>
          <div className="sm-queue-item-actions">
            {queue.isActive ? (
              <div
                className="sm-queue-item-action"
                onClick={() => handleSetActivateQueue(false)}
              >
                <img alt="" src={iconAlarmOff} />
                {t("deactivate_queue")}
              </div>
            ) : (
              <div
                className="sm-queue-item-action"
                onClick={() => handleSetActivateQueue(true)}
              >
                <img alt="" src={iconAlarmOn} />
                {t("activate_queue")}
              </div>
            )}
            {queue.isTicketingOpen ? (
              <div
                className="sm-queue-item-action"
                onClick={() => handleSetQueueTicketing(false)}
              >
                <img alt="" src={iconPause} />
                {t("pause_tickets")}
              </div>
            ) : (
              <div
                className="sm-queue-item-action"
                onClick={() => handleSetQueueTicketing(true)}
              >
                <img alt="" src={iconStart} />
                {t("start_tickets")}
              </div>
            )}
            <div
              className="sm-queue-item-action"
              onClick={() => handleSetQueueingTime()}
            >
              <img alt="" src={iconTimer} />
              {t("set_queueing_time")}
            </div>
            <div
              className="sm-queue-item-action"
              onClick={() => handleResetQueue()}
            >
              <img
                alt=""
                src={iconDelete}
                className="sm-queue-item-icon-delete"
              />
              {t("reset_queue")}
            </div>
            <div style={{ textAlign: "center", marginTop: "1em" }}>
              <Button
                label={t("go_to_doorman")}
                color={"secondary"}
                onClick={() =>
                  history.push(
                    URLS.storeManager.doorman
                      .replace(":id", storeId)
                      .replace(":qid", queue.id)
                  )
                }
              />
            </div>
          </div>
        </BlockArea>
      )}
    </div>
  );
};

export default QueueRow;
