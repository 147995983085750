import TermsAndConditions from "./TermsAndConditions";
import React from "react";
import { Queue, Shopper } from "reduxstore/types";
import ShareAppLink from "./ShareAppLink";
import { useTranslation } from "react-i18next";
import QueueService from "../../../services/db/QueueService";

interface Props {
  storeId: string;
  queue: Queue;
  shopper?: Shopper;
  approved: boolean;
  setApproved: (e: boolean) => void;
}

const ActionText = ({
  storeId,
  shopper,
  approved,
  setApproved,
  queue,
}: Props) => {
  const { t } = useTranslation();

  if (!QueueService.isQueueAvailable(queue)) {
    return (
      <div className="spaced" style={{ marginBottom: "1em" }}>
        {t("queue_is_not_available")}
      </div>
    );
  }

  if (QueueService.canJoinQueue(shopper)) {
    return <TermsAndConditions approved={approved} setApproved={setApproved} />;
  }

  if (!shopper) return null;

  if (shopper.status === "waiting" || shopper.status === "in_store") {
    return <ShareAppLink storeId={storeId} />;
  }

  if (shopper.status === "to_approve") {
    return (
      <div
        className="spaced"
        style={{ fontWeight: "bold", textAlign: "center", marginBottom: "1em" }}
      >
        {t("ask_to_approve_waiting")}
      </div>
    );
  }

  if (shopper.status === "approved") {
    return (
      <div
        className="spaced"
        style={{ fontWeight: "bold", textAlign: "center", marginBottom: "1em" }}
      >
        {t("approved_waiting")}
      </div>
    );
  }

  if (shopper.status === "let_in") {
    return (
      <div
        className="spaced"
        style={{ textAlign: "center", marginBottom: "1em" }}
      >
        {t("you_re_let_in")}
      </div>
    );
  }

  return null;
};

export default ActionText;
