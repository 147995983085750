import { MapOf } from "../reduxstore/types";

const configs = {
  email: {
    from: "info@q-in.app",
  },
  aws: {
    region: "eu-central-1",
  },
  expiration: {
    hours: {
      userInvite: 24,
    },
  },
  external: {
    termsURL: "https://vartechsolutions.com/q-in-terms-and-conditions/",
    privacyURL: "https://vartechsolutions.com/privacy-and-cookie-policy/",
  },
  cookieConsentKey: "cookie_consent",
};

export const phoneFormats: MapOf<string> = {
  "+44": "#### ### ###",
  "+90": "### ### ####",
  "+91": "#### ### ###",
};

export const notifyUserCount = 2;

export default configs;
