import React from "react";
import { useAppDispatch, useAppSelector } from "../../reduxstore";
import Loading from "../../components/Loading";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { URLS } from "../../utils/constants";
import "./style.css";
import TokenService from "../../services/db/TokenService";
import LoginPage from "./LoginPage";
import StoreService from "../../services/db/StoreService";
import { saveUserAction } from "../../reduxstore/reducers/user";

const AuthIndex = () => {
  const auth = useAppSelector(({ firebase: { auth, profile } }) => auth);
  const { token } = useParams<{ token?: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const saveUserStatus = useAppSelector((state) => state.user.saveUserStatus);

  if (auth.isEmpty || auth.isAnonymous) return <LoginPage />;

  if (saveUserStatus === "idle") {
    console.log(auth);
    const email = () => {
      if (
        auth.providerData &&
        auth.providerData.length > 0 &&
        // @ts-ignore
        auth.providerData[0].email
      ) {
        // @ts-ignore
        return auth.providerData[0].email;
      }
      return "email-not-provided-with-auth";
    };
    dispatch(
      saveUserAction({
        id: auth.uid,
        email: email(),
        providerId:
          auth.providerData && auth.providerData.length > 0
            ? auth.providerData[0].providerId
            : "unknown",
      })
    );
  }

  const processToken = async (token: string): Promise<string | null> => {
    const [storeId, tokenValue] = token.split("-");
    const tokenData = await TokenService.get(storeId, tokenValue);
    if (tokenData) {
      if (tokenData.purpose === "storeInvitation") {
        await StoreService.addUserToStore(
          tokenData.storeId,
          auth.uid,
          tokenData.role
        );
        await TokenService.delete(storeId, tokenValue);
        return tokenData.storeId;
      }
    }
    return null;
  };

  if (token) {
    processToken(token)
      .then((storeId) => {
        if (storeId) {
          history.push(
            URLS.storeManager.storeDashboard.replace(":id", storeId)
          );
        } else {
          history.push(URLS.storeManager.root);
        }
      })
      .catch((err) => {
        console.error("Invalid Token", err);
        history.push(URLS.storeManager.root);
      });

    return <Loading />;
  } else {
    return <Redirect to={{ pathname: URLS.storeManager.root }} />;
  }
};

export default AuthIndex;
