import React from "react";
import SMSummary from "components/SMSummary";
import { useTranslation } from "react-i18next";
import { useFirestoreConnect } from "react-redux-firebase";
import { Queue, Store } from "reduxstore/types";
import { useAppSelector } from "../../../../reduxstore";
import StoreService from "services/db/StoreService";
import Loading from "components/Loading";
import Button from "components/Button";
import SMHeader from "components/SMHeader";
import Layout from "components/FullHeightLayout";
import QueueRow from "./QueueRow";
import "./style.css";

interface Props {
  userId: string;
  store: Store;
}

const QueueListPage = ({ userId, store }: Props) => {
  const { t } = useTranslation();

  const queues = useAppSelector((state) => state.firestore.ordered.storeQueues);

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: store.id,
      subcollections: [{ collection: "queues" }],
      storeAs: "storeQueues",
    },
  ]);

  if (queues === undefined) {
    return <Loading />;
  }

  const handleAddQueue = async () => {
    await StoreService.addQueueToStore(userId, store.id, "name_will_be_input");
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="spaced">
        <SMSummary store={store} />
      </div>
      <div className="sm-queue-list">
        {queues.map((q: Queue) => (
          <QueueRow key={q.id} storeId={store.id} queue={q} />
        ))}
      </div>
      <div className="sm-queue-list-add">
        <Button label={t("add_queue")} onClick={handleAddQueue} />
      </div>
    </Layout>
  );
};

export default QueueListPage;
