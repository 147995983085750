import React from "react";
import Thumb from "components/Thumb";
import { Store } from "reduxstore/types";
import "./style.css";

interface Props {
  store: Store;
}

const SMSummary = ({ store }: Props) => {
  return (
    <div className="sm-store-summary">
      <Thumb src={store.imageURL} />
      <div style={{ marginTop: "1em" }}>{store.intro}</div>
    </div>
  );
};

export default SMSummary;
