import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../components/Checkbox";
import configs from "config/configs";

interface Props {
  approved: boolean;
  setApproved: (e: boolean) => void;
}

const TermsAndConditions = ({ approved, setApproved }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="spaced" style={{ marginBottom: "1em" }}>
      <label>
        <Checkbox
          checked={approved}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setApproved(e.target.checked)
          }
        />
        <span style={{ marginLeft: ".5em" }}>{t("i_approve")}</span>
      </label>
      <a
        href={configs.external.termsURL}
        target="_blank"
        rel="noreferrer"
        style={{ marginLeft: ".2em", fontWeight: "bold" }}
      >
        {t("t_and_a")}
      </a>
    </div>
  );
};

export default TermsAndConditions;
