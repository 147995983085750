import React from "react";
import NumberFormat from "react-number-format";
import { phoneFormats } from "../../../../config/configs";

interface Props {
  onChange: (phone: string) => void;
}

const PhoneInput = ({ onChange }: Props) => {
  const getFormat = (unformatted: string): string[] => {
    const validPrefixes = Object.keys(phoneFormats);
    const matchingPrefixes = validPrefixes.filter(
      (prefix) => `+${unformatted.substr(0, prefix.length - 1)}` === prefix
    );
    if (matchingPrefixes.length === 0) {
      return ["", ""];
    }
    const format = phoneFormats[matchingPrefixes[0]];
    return [format, matchingPrefixes[0]];
  };

  const handleChange = (value: string) => {
    const sanitizedPhone = `+${value.replace(/[^\d]/g, "")}`;
    const [format, prefix] = getFormat(sanitizedPhone.substr(1));
    if (
      format.replace(/\s/g, "").length + prefix.length ===
      sanitizedPhone.length
    ) {
      onChange(sanitizedPhone);
    } else {
      onChange("");
    }
  };

  const formatter = (unformatted: string) => {
    const [format, prefix] = getFormat(unformatted);
    if (!prefix) {
      return `+${unformatted}`;
    }
    const chunks = [prefix.length - 1].concat(
      format.split(" ").map((part) => part.length)
    );
    const groups = chunks.map((count, idx) => {
      const drop = chunks.slice(0, idx).reduce((acc, c) => acc + c, 0);
      return unformatted.substr(drop, count);
    });
    return `+${groups.filter((g) => g.length > 0).join(" ")}`;
  };

  return (
    <NumberFormat
      defaultValue="+44"
      format={formatter}
      allowEmptyFormatting
      mask="_"
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default PhoneInput;
