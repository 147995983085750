import React from "react";

interface StaticMessageProps {
  title: string;
  subtitle: string;
  subtitleBig?: boolean;
}

const StaticMessage = ({
  title,
  subtitle,
  subtitleBig,
}: StaticMessageProps) => {
  const cn = subtitleBig
    ? "shopper-queue-summary-text-subtitle--big"
    : "shopper-queue-summary-text-subtitle";

  return (
    <div className="shopper-queue-summary-container">
      <div className="shopper-queue-summary-static">
        <div className="shopper-queue-summary-text-title">{title}</div>
        <div className={cn}>{subtitle}</div>
      </div>
    </div>
  );
};

export default StaticMessage;
