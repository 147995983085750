import React from "react";
import { Switch, Route, useParams, useRouteMatch } from "react-router-dom";
import { useAppSelector } from "reduxstore";
import Loading from "components/Loading";
import StoreDashboard from "./Dashboard";
import StoreQueuePage from "./Queue";
import QueueListPage from "./QueueList";
import StoreQRPage from "./QR";
import StoreLinkPage from "./StoreLink";
import CreateStorePage from "../CreateStore";
import StoreManualQueuePage from "./ManualQueue";
import { useFirestoreConnect } from "react-redux-firebase";
import AddUserPage from "./Invite";
import Error403 from "../../Error/403";

const StoreManagerStoreIndex = () => {
  const { id } = useParams<{ id: string }>();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;
  let { path } = useRouteMatch();
  const loadingCompleted = useAppSelector((state) => {
    return (
      state.firestore.status.requested[`stores/${id}`] &&
      state.firestore.status.requested.storeManager
    );
  });

  const dbStore = useAppSelector(
    (state) => state.firestore.data.stores && state.firestore.data.stores[id]
  );

  const storeManager = useAppSelector(
    (state) => state.firestore.data.storeManager
  );

  useFirestoreConnect(() => [
    { collection: "stores", doc: id },
    {
      collection: "stores",
      doc: id,
      storeAs: "storeManager",
      subcollections: [{ collection: "users", doc: userId }],
    },
  ]);

  if (!loadingCompleted) {
    return <Loading />;
  }
  const store = { ...dbStore, id };

  if (!storeManager) {
    return <Error403 />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/queues`}>
        <QueueListPage store={store} userId={userId} />
      </Route>
      <Route exact path={`${path}/queue/:queueId/user`}>
        <StoreManualQueuePage store={store} />
      </Route>
      <Route exact path={`${path}/queue/:queueId`}>
        <StoreQueuePage store={store} />
      </Route>
      <Route exact path={`${path}/qr`}>
        <StoreQRPage store={store} />
      </Route>
      <Route exact path={`${path}/link`}>
        <StoreLinkPage store={store} />
      </Route>
      <Route exact path={`${path}/invite`}>
        <AddUserPage store={store} />
      </Route>
      <Route path={`${path}/edit`}>
        <CreateStorePage existing={store} />
      </Route>
      <Route>
        <StoreDashboard store={store} userId={userId} />
      </Route>
    </Switch>
  );
};

export default StoreManagerStoreIndex;
