import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import configs from "config/configs";
import "./style.css";
import Modal from "../Modal";

const CookieConsent = () => {
  const SECONDS = 0;
  type ConsentStatus = "accepted" | "rejected" | null;
  const { t } = useTranslation();
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(configs.cookieConsentKey) === null) {
      const timer = setTimeout(() => {
        setDisplayed(true);
      }, SECONDS * 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const store = (value: ConsentStatus) => {
    if (value) localStorage.setItem(configs.cookieConsentKey, value);
    setDisplayed(false);
  };

  const handleAccept = () => store("accepted");

  const handleReject = () => store("rejected");

  if (!displayed) {
    return null;
  }

  return (
    <Modal displayed={true}>
      <div
        className="cookie_consent-body"
        dangerouslySetInnerHTML={{
          __html: t("cookie_consent_message", {
            url: "https://vartechsolutions.com/q-in",
          }),
        }}
      />
      <div className="cookie_consent-buttons">
        <div
          className="cookie_consent-btn--reject"
          onClick={() => handleReject()}
        >
          {t("cookie_consent_reject")}
        </div>
        <div
          className="cookie_consent-btn--accept"
          onClick={() => handleAccept()}
        >
          {t("cookie_consent_accept")}
        </div>
      </div>
    </Modal>
  );
};

export default CookieConsent;
