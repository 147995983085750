import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LogoMenu } from "components/Logo";
import Loading from "components/Loading";
import Button from "components/Button";
import Layout, { Bottom } from "components/FullHeightLayout";
import { useAppSelector } from "reduxstore";
import { Queue, Shopper, Store } from "reduxstore/types";
import StoreService from "services/db/StoreService";
import QueueRow from "./QueueRow";
import StoreManagerError from "../../Error";
import "./style.css";
import { URLS } from "../../../../utils/constants";
import NotificationService from "../../../../services/db/NotificationService";
import { NotifyUserInfo } from "../../../../services/db/QueueService";
import SMHeader from "../../../../components/SMHeader";

interface Props {
  store: Store;
}

const StoreQueue = ({ store }: Props) => {
  const { t } = useTranslation();
  const { queueId } = useParams<{ queueId: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: store.id,
      storeAs: "queue",
      subcollections: [
        {
          collection: "queues",
          doc: queueId,
        },
      ],
    },
    {
      collection: "stores",
      doc: store.id,
      storeAs: "shoppers",
      subcollections: [
        {
          collection: "queues",
          doc: queueId,
          subcollections: [
            {
              collection: "shoppers",
              orderBy: ["ticketNo", "asc"],
            },
          ],
        },
      ],
    },
  ]);

  const queueLoaded = useAppSelector(
    (state) => state.firestore.status.requested.queue
  );
  const queue = useAppSelector<Queue>((state) => state.firestore.data.queue);
  const shopperList = useAppSelector<Array<Shopper>>(
    (state) => state.firestore.ordered.shoppers
  );

  if (!store || !queueLoaded || !shopperList) {
    return <Loading />;
  }

  if (!queue) {
    return (
      <StoreManagerError
        message={t("doorman_error_queue_deleted")}
        url={URLS.storeManager.root}
        btnLabel={t("doorman_error_btn")}
        storeId={store.id}
      />
    );
  }

  if (!queue.isActive) {
    return (
      <StoreManagerError
        message={t("doorman_error_queue_inactive")}
        storeId={store.id}
      />
    );
  }

  const queueService = StoreService.getQueue(store.id, queueId);
  const firstTicket: number = queue.waitingTickets[0];

  const notifyUsers = (list: NotifyUserInfo[]) => {
    list.forEach((info) =>
      NotificationService.sms(store.id, {
        to: info.phoneNo,
        text: t("sms_doorman_notify_user", { ticketNo: info.ticketNo }),
      })
    );
  };

  const handleNext = async () => {
    setLoading(true);
    const usersToBeSMSed = await queueService.doormanNext(firstTicket);
    notifyUsers(usersToBeSMSed);
    setLoading(false);
  };

  const handleRemove = async () => {
    setLoading(true);
    const usersToBeSMSed = await queueService.doormanRemove(firstTicket);
    notifyUsers(usersToBeSMSed);
    setLoading(false);
  };

  const previousTickets = shopperList.filter(
    (shopper) =>
      shopper.ticketNo < firstTicket && queueService.wasLetIn(shopper.status)
  );
  const nextTickets = shopperList.filter(
    (shopper) =>
      shopper.ticketNo > firstTicket &&
      queueService.isStillWaiting(shopper.status)
  );
  const lastShopperIn =
    previousTickets.length > 0
      ? [previousTickets[previousTickets.length - 1]]
      : [];

  const filtered = shopperList.filter(
    (shopper) => shopper.ticketNo === firstTicket
  );
  const nextShopper = filtered.length ? filtered[0] : [];
  const nextShoppers = nextTickets.slice(0, 4);

  const filteredQueue = lastShopperIn.concat(nextShopper).concat(nextShoppers);

  const getInfoMessage = () => {
    if (queue.waitingTickets.length > 0) {
      return (
        <div className="sm-queue-info">
          <span>{queue.waitingTickets.length}</span>
          <span>{t("doorman_queue_count")}</span>
          <span>
            <LogoMenu />
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="sm-queue-table">
        {filteredQueue.length === 0 && (
          <div className="sm-queue-empty-text">
            {t("doorman_queue_is_empty")}
          </div>
        )}
        {filteredQueue.map((q) => (
          <QueueRow
            loading={loading}
            key={q.id}
            shopper={q}
            isFirstTicket={q.ticketNo === firstTicket}
            handleRemove={handleRemove}
          />
        ))}
      </div>
      {getInfoMessage()}
      <Bottom>
        <div style={{ paddingBottom: "1.5em" }}>
          <Link
            to={URLS.storeManager.addUserToQueue
              .replace(":id", store.id)
              .replace(":qid", queueId)}
          >
            {t("doorman_add_user")}
          </Link>
        </div>
        <Button
          label={t("next")}
          onClick={handleNext}
          disabled={loading || filteredQueue.length === 0}
        />
      </Bottom>
    </Layout>
  );
};

export default StoreQueue;
