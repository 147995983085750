import firebaseApp from "api/firebase.config";
import { BaseService } from "./BaseService";
import configs from "../../config/configs";
import { TokenPurpose } from "../../reduxstore/types";
import { DBCOLLECTIONS } from "../../api/services/dbConstants";

class TokenService extends BaseService {
  list(storeId: string) {
    return this.firestore
      .collection(DBCOLLECTIONS.STORES)
      .doc(storeId)
      .collection("tokens");
  }

  async create(
    storeId: string,
    purpose: TokenPurpose,
    payload: firebaseApp.firestore.DocumentData
  ) {
    const newDocRef = this.list(storeId).doc();
    const id = newDocRef.id;

    await newDocRef.set({
      purpose,
      ...payload,
      createdAt: firebaseApp.firestore.FieldValue.serverTimestamp(),
    });

    return id;
  }

  hoursAgo(hours: number) {
    return Date.now() - hours * 60 * 60 * 1000;
  }

  async get(
    storeId: string,
    id: string
  ): Promise<firebaseApp.firestore.DocumentData | null> {
    const doc = await this.list(storeId).doc(id).get();
    if (!doc.exists) {
      console.error("Token does not exists");
      return null;
    }
    const data = doc.data()!;

    if (
      data["createdAt"].toMillis() <
      this.hoursAgo(configs.expiration.hours.userInvite)
    ) {
      console.error("Token expired");
      return null;
    }

    return data;
  }

  async delete(storeId: string, id: string) {
    const docRef = this.list(storeId).doc(id);
    await docRef.delete();
  }
}

export default new TokenService(firebaseApp.firestore());
