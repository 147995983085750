import React from "react";
import { Helmet } from "react-helmet";
import { brand, URLS } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Button from "../../components/Button";

const title = brand.name + " - Error";
const description = brand.desc;

const Error403 = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          height: "90vh",
        }}
      >
        <h3>{t("error_page_title_403")}</h3>
        <p>{t("error_page_message_403")}</p>
        <Link to={URLS.storeManager.root}>
          <Button label={t("error_page_go_to_home")} />
        </Link>
      </div>
    </div>
  );
};

export default Error403;
