import React from "react";
import cx from "classnames";
import Spinner from "../Spinner";
import { MayBeElement } from "../../reduxstore/types";
import "./style.css";

interface Props {
  active?: boolean;
  children: MayBeElement;
}

const BlockArea = ({ children, active }: Props) => {
  return (
    <div className="blockarea-container">
      {active && (
        <div
          className={cx("blockarea-blocker", {
            active: "blockarea-blocker--active",
          })}
        >
          <Spinner />
        </div>
      )}
      {children}
    </div>
  );
};

export default BlockArea;
