import { ReactComponent as IconWaiting } from "assets/ic_hourglass_empty_24px.svg";
import { ReactComponent as IconTimer } from "assets/ic_timer_24px.svg";
import { ReactComponent as IconTick } from "assets/ic_done_24px.svg";
import { ReactComponent as IconBlock } from "assets/ic_block_24px.svg";
import { ReactComponent as IconDone } from "assets/ic_arrow_forward_24px.svg";
import iconThumbsDown from "assets/ic_thumbs_down.svg";
import React, { useEffect, useState } from "react";
import { Shopper } from "reduxstore/types";
import Spinner from "../../../../components/Spinner";

interface QueueRowProps {
  loading: boolean;
  shopper: Shopper;
  isFirstTicket: boolean;
  handleRemove: (tokenNo: number) => void;
}

const QueueRow = ({
  loading,
  isFirstTicket,
  shopper,
  handleRemove,
}: QueueRowProps) => {
  const getNow = () => Math.round(new Date().getTime() / 1000);
  const [now, setNow] = useState<number>(getNow());
  const APPROVAL_THRESHOLD_MINS = 2;
  const isMoreThanAllowedTime =
    shopper.notifiedAt &&
    shopper.notifiedAt.seconds < now - APPROVAL_THRESHOLD_MINS * 60;

  useEffect(() => {
    if (shopper.status === "to_approve" && !isMoreThanAllowedTime) {
      const timer = setTimeout(() => {
        setNow(getNow());
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [now, shopper.status, isMoreThanAllowedTime]);

  const getIcon = () => {
    switch (shopper.status) {
      case "waiting":
        return <IconWaiting />;
      case "to_approve":
        if (isMoreThanAllowedTime) return <IconBlock />;
        return <IconTimer />;
      case "approved":
        return <IconTick />;
      case "let_in":
      case "in_store":
      case "left":
      case "removed":
      case "done":
        return <IconDone />;
      default:
        return null;
    }
  };

  const tokenNoClasses = ["sm-queue-row-item"];
  if (isFirstTicket) {
    tokenNoClasses.push("next");
  } else if (
    shopper.status === "let_in" ||
    shopper.status === "in_store" ||
    shopper.status === "done" ||
    shopper.status === "left" ||
    shopper.status === "removed"
  ) {
    tokenNoClasses.push("done");
  }

  return (
    <div className="sm-queue-row">
      <span className="sm-queue-row-item--left">
        {isFirstTicket && loading ? <Spinner small={true} /> : " "}
      </span>
      <span className={tokenNoClasses.join(" ")}>{shopper.ticketNo}</span>
      <span className="sm-queue-row-item--right">
        {getIcon()}
        {shopper.status === "to_approve" &&
          !loading &&
          isMoreThanAllowedTime && (
            <img
              src={iconThumbsDown}
              alt=""
              onClick={() => handleRemove(shopper.ticketNo)}
            />
          )}
      </span>
    </div>
  );
};

export default QueueRow;
