import React from "react";
import "./style.css";
import { MayBeElement } from "../../reduxstore/types";

interface Props {
  children: MayBeElement | MayBeElement[];
}

export const Bottom = ({ children }: Props) => {
  return <div className="layout-bottom-container">{children}</div>;
};

export const Middle = ({ children }: Props) => {
  return <div className="layout-middle-container">{children}</div>;
};

const FullHeightLayout = ({ children }: Props) => {
  return <div className="layout-container">{children}</div>;
};

export default FullHeightLayout;
