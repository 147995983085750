import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import React from "react";

interface SelectProps {
  description: string;
}

interface Props {
  placeholder: string;
  handleAddressChange: (lat: number, lng: number) => void;
}

const PlacesAutocomplete = ({ placeholder, handleAddressChange }: Props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.currentTarget.value);
  };

  const handleSelect = ({ description }: SelectProps) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        handleAddressChange(lat, lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () => {
    if (status !== "OK") {
      return null;
    }
    const list = data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

    return <ul>{list}</ul>;
  };

  return (
    <div className="location-suggestions">
      <div className="location-suggestions-input-wrapper">
        <input
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={placeholder}
        />
        {renderSuggestions()}
      </div>
    </div>
  );
};

export default PlacesAutocomplete;
