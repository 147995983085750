import React from "react";
import { MayBeElement } from "../../reduxstore/types";
import "./style.css";

interface Props {
  displayed: boolean;
  onDismiss: () => void;
  children: MayBeElement | MayBeElement[];
}

const Modal = ({ children, displayed, onDismiss }: Props) => {
  if (!displayed) {
    return null;
  }

  return (
    <div className="modal-container" onClick={onDismiss}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  onDismiss: () => {},
};

export default Modal;
