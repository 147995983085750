import Spinner from "components/Spinner";
import StoreService from "services/db/StoreService";
import Button from "components/Button";
import { ReactComponent as ExitIcon } from "assets/ic_exit_to_app_24px.svg";
import React, { useState } from "react";
import { Queue, Shopper } from "reduxstore/types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "reduxstore";
import QueueService from "../../../services/db/QueueService";

interface Props {
  storeId: string;
  queue: Queue;
  shopper?: Shopper;
  approved: boolean;
}

const ActionButton = ({ storeId, queue, shopper, approved }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;
  const queueService = StoreService.getQueue(storeId, queue.id);

  const onQueueIn = async () => {
    if (!approved) return false;

    setLoading(true);
    await queueService.userJoinsQueue({ userId });
    setLoading(false);
  };

  const onLeaveQueue = async () => {
    setLoading(true);
    await queueService.userLeavesQueue(userId);
    setLoading(false);
  };

  const onApprove = async () => {
    setLoading(true);
    await queueService.userApprovesAvailability(userId);
    setLoading(false);
  };

  const onGoingIn = async () => {
    setLoading(true);
    await queueService.userGoesIn(userId);
    setLoading(false);
  };

  const onDone = async () => {
    setLoading(true);
    await queueService.userIsDone(userId);
    setLoading(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!QueueService.isQueueAvailable(queue)) {
    return <Button label={t("please_wait")} disabled />;
  }

  if (QueueService.canJoinQueue(shopper)) {
    return (
      <Button label={t("queue_now")} onClick={onQueueIn} disabled={!approved} />
    );
  }

  if (!shopper) return null;

  if (shopper.status === "waiting") {
    return (
      <Button
        label={t("queue_leave")}
        onClick={onLeaveQueue}
        color="secondary"
        Icon={ExitIcon}
      />
    );
  }

  if (shopper.status === "to_approve") {
    return <Button label={t("ok")} onClick={onApprove} />;
  }

  if (shopper.status === "approved") {
    return <Button label={t("please_wait")} disabled />;
  }

  if (shopper.status === "let_in") {
    return <Button label={t("im_going_in")} onClick={onGoingIn} />;
  }

  if (shopper.status === "in_store") {
    return <Button label={t("im_done")} onClick={onDone} />;
  }

  return null;
};

export default ActionButton;
