import { LogoMenu } from "../Logo";
import DrawerMenu from "../DrawerMenu";
import React, { useState } from "react";
import iconMenuClose from "../../assets/ic_menu_close.svg";
import iconMenuOpen from "../../assets/ic_menu.svg";
import { MapOf, StoreSummary } from "../../reduxstore/types";
import { useAppSelector } from "../../reduxstore";

interface Props {
  title?: string;
  currentStoreId?: string;
}

const SMHeader = ({ title, currentStoreId }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const storeList = useAppSelector<MapOf<StoreSummary>>(
    (state) => state.store.stores
  );
  const storeIds = Object.keys(storeList);

  const store = currentStoreId
    ? storeList[currentStoreId]
    : storeIds.length > 0
    ? storeList[storeIds[0]]
    : undefined;

  const getIcon = () => {
    const st = { height: 16 };
    if (isMenuOpen) return <img alt="" src={iconMenuClose} style={st} />;

    return <img alt="" src={iconMenuOpen} style={st} />;
  };

  let titleWithDefaultStoreName = title;
  if (store && title === undefined) {
    titleWithDefaultStoreName = store.name;
  }

  return (
    <div>
      <div className="sm-header no-print">
        <span onClick={() => setMenuOpen(!isMenuOpen)} style={{ width: 24 }}>
          {getIcon()}
        </span>
        <span>{titleWithDefaultStoreName}</span>
        <LogoMenu />
      </div>
      <div style={{ position: "relative" }}>
        <DrawerMenu
          open={isMenuOpen}
          onSelect={() => setMenuOpen(false)}
          store={store}
          multipleStores={storeIds.length > 1}
        />
      </div>
    </div>
  );
};

export default SMHeader;
