import React from "react";
import SMSummary from "components/SMSummary";
import { useTranslation } from "react-i18next";
import { Store } from "reduxstore/types";
import "./style.css";
import { useAppSelector } from "../../../../reduxstore";
import { useFirestoreConnect } from "react-redux-firebase";
import QueueRow from "../QueueList/QueueRow";
import Loading from "components/Loading";
import Layout from "components/FullHeightLayout";
import SMHeader from "components/SMHeader";

interface Props {
  userId: string;
  store: Store;
}

const StoreDashboard = ({ store }: Props) => {
  const { t } = useTranslation();

  const queue = useAppSelector((state) => state.firestore.data.queue);

  useFirestoreConnect(() => [
    {
      collection: "stores",
      doc: store.id,
      storeAs: "queue",
      subcollections: [
        {
          collection: "queues",
          doc: store.activeQueueId,
        },
      ],
    },
  ]);

  if (!queue) {
    return <Loading />;
  }
  //
  // const handleDelete = async () => {
  //   await StoreService.deleteStore(userId, store.id);
  //   history.push(URLS.storeManager.root);
  // };
  // <div
  //   className="sm-dashboard-item sm-dashboard-item--red"
  //   onClick={handleDelete}
  // >
  //   {t("delete_store")}
  // </div>

  const modifiedQueue = {
    ...queue,
    id: store.activeQueueId,
    name: t("queue"),
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="spaced" style={{ marginTop: "2em", marginBottom: "2em" }}>
        <SMSummary store={store} />
      </div>
      <div>
        <QueueRow
          queue={modifiedQueue}
          storeId={store.id}
          initiallyExpanded={true}
        />
      </div>
    </Layout>
  );
};

export default StoreDashboard;
