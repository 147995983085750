import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../reduxstore";
import { Link } from "react-router-dom";
import { URLS } from "../../../utils/constants";
import { MapOf, StoreSummary } from "../../../reduxstore/types";
import SMHeader from "../../../components/SMHeader";
import "./style.css";
import Thumb from "../../../components/Thumb";
import Button from "../../../components/Button";

const StoreManagerSelectStore = () => {
  const { t } = useTranslation();

  const storeList = useAppSelector<MapOf<StoreSummary>>(
    (state) => state.store.stores
  );

  return (
    <div>
      <SMHeader title={t("select_store")} />
      {Object.values(storeList).map((store) => {
        return (
          <div className="store-list-row-container" key={store.id}>
            <Link
              to={URLS.storeManager.storeDashboard.replace(
                ":id",
                store.id.toString()
              )}
            >
              <div className="store-list-row spaced">
                <div className="store-list-image">
                  <Thumb src={store.imageURL} />
                </div>
                <div className="store-list-name">{store.name}</div>
              </div>
            </Link>
          </div>
        );
      })}
      <div
        style={{ marginTop: "1em", paddingBottom: "1em", textAlign: "center" }}
      >
        <Link to={URLS.storeManager.createStore}>
          <Button label={t("add_new_store")} />
        </Link>
      </div>
    </div>
  );
};

export default StoreManagerSelectStore;
