import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isLoaded } from "react-redux-firebase";
import { URLS } from "./utils/constants";
import StoreManagerIndex from "./screens/StoreManager";
import ShopperIndex from "./screens/Shopper";
import AuthIndex from "./screens/Auth";
import Loading from "./components/Loading";
import { useAppSelector } from "./reduxstore";
import { MayBeElement } from "./reduxstore/types";
import CookieConsent from "./components/CookieConsent";
import Spinner from "./components/Spinner";
import AppUpdate from "./components/AppUpdate";

interface Props {
  children: MayBeElement;
}

function AuthIsLoaded({ children }: Props) {
  const auth = useAppSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loading />;
  return children;
}

function App() {
  return (
    <div className="App">
      <Suspense fallback={<Spinner />}>
        <Router>
          <AuthIsLoaded>
            <Switch>
              <Route exact path={URLS.auth} component={AuthIndex} />
              <Route exact path={URLS.authWithToken} component={AuthIndex} />
              <Route
                path={URLS.storeManager.root}
                component={StoreManagerIndex}
              />
              <Route path={URLS.shopper.root} component={ShopperIndex} />
              <Route render={() => <Redirect to={URLS.shopper.root} />} />
            </Switch>
          </AuthIsLoaded>
        </Router>
        <CookieConsent />
        <AppUpdate />
      </Suspense>
    </div>
  );
}

export default App;
