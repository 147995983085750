import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "reduxstore";
import {
  MapOf,
  ProgressState,
  ShopperSummary,
  StoreCreateData,
  StoreSummary,
} from "../types";
import StoreService from "../../services/db/StoreService";

export interface StoreState {
  saveStoreStatus: ProgressState;
  savedStoreId?: string;
  fetchStoresStatus: ProgressState;
  fetchQueueStatus: ProgressState;
  stores: MapOf<StoreSummary>;
  queues: { [id: string]: ShopperSummary[] };
}

const initialState: StoreState = {
  saveStoreStatus: "idle",
  fetchStoresStatus: "idle",
  fetchQueueStatus: "idle",
  stores: {},
  queues: {},
};

interface QueueSuccessPayload {
  id: string;
  list: ShopperSummary[];
}

const reducer = createSlice({
  name: "store",
  initialState,
  reducers: {
    fetchStoresInit(state) {
      state.fetchStoresStatus = "loading";
    },
    fetchStoresSuccess(state, action: PayloadAction<Array<StoreSummary>>) {
      state.fetchStoresStatus = "succeeded";
      action.payload.forEach((ss) => {
        state.stores[ss.id] = ss;
      });
    },
    fetchStoresError(state) {
      state.fetchStoresStatus = "failed";
    },
    fetchQueueInit(state) {
      state.fetchQueueStatus = "loading";
    },
    fetchQueueSuccess(state, action: PayloadAction<QueueSuccessPayload>) {
      state.fetchQueueStatus = "succeeded";
      state.queues[action.payload.id] = action.payload.list;
    },
    fetchQueueError(state) {
      state.fetchQueueStatus = "failed";
    },
    saveStoreInit(state) {
      state.saveStoreStatus = "loading";
    },
    saveStoreSuccess(state, action: PayloadAction<string>) {
      state.saveStoreStatus = "succeeded";
      state.savedStoreId = action.payload;
    },
    saveStoreError(state) {
      state.saveStoreStatus = "failed";
    },
    saveStoreReset(state) {
      state.savedStoreId = undefined;
      state.saveStoreStatus = "idle";
    },
  },
});

const {
  fetchStoresInit,
  fetchStoresSuccess,
  fetchStoresError,
  fetchQueueInit,
  fetchQueueSuccess,
  fetchQueueError,
  saveStoreInit,
  saveStoreSuccess,
  saveStoreError,
} = reducer.actions;

export const { saveStoreReset } = reducer.actions;

export const fetchStoresAction = (ids: Array<string>) => async (
  dispatch: AppDispatch
) => {
  dispatch(fetchStoresInit());
  try {
    const res = await StoreService.fetchStores(ids);
    dispatch(fetchStoresSuccess(res));
  } catch (e) {
    console.log(e);
    dispatch(fetchStoresError());
  }
};
export const fetchQueueAction = (storeId: string, queueId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(fetchQueueInit());
  try {
    const res = await StoreService.fetchShoppersInQueue(storeId, queueId);
    dispatch(fetchQueueSuccess({ id: queueId, list: res }));
  } catch (e) {
    dispatch(fetchQueueError());
  }
};
export const createStoreAction = (
  userId: string,
  store: StoreCreateData
) => async (dispatch: AppDispatch) => {
  dispatch(saveStoreInit());
  try {
    const storeId = await StoreService.createStore(userId, store);
    dispatch(saveStoreSuccess(storeId));
    dispatch(
      fetchStoresSuccess([
        { id: storeId, name: store.name, imageURL: store.imageURL },
      ])
    );
  } catch (e) {
    dispatch(saveStoreError());
  }
};

export default reducer;
