import firebaseApp from "api/firebase.config";
import { AnalyticsService } from "./AnalyticsService";
import { FB_EVENT_NAMES } from "../utils/constants";
import { uuidv4 } from "../utils/helpers";

class ImageService {
  storage: firebaseApp.storage.Storage;
  analyticsService: AnalyticsService;

  constructor(storage: firebaseApp.storage.Storage) {
    this.storage = storage;
    this.analyticsService = new AnalyticsService();
  }

  async uploadImage(userId: string, file: File): Promise<string> {
    const uuid = uuidv4();
    const uploadTask = this.storage
      .ref()
      .child(`/images/store/${uuid}`)
      .put(file, {
        customMetadata: { uploadedBy: userId },
      });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => reject(error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve(downloadURL);
            this.analyticsService.log(FB_EVENT_NAMES.imageUploaded, { userId });
          });
        }
      );
    });
  }
}

export default new ImageService(firebaseApp.storage());
