import React, { useRef, useState } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import PlacesAutocomplete from "./PlacesAutocomplete";
import { useTranslation } from "react-i18next";
import "./style.css";

interface Props {
  google: any;
  zoom: number;
  lat: number;
  lng: number;
  onChange: (loc: any) => void;
}

const MapContainer = ({ google, onChange, zoom, lat, lng }: Props) => {
  const { t } = useTranslation();
  const mapRef = useRef(null);
  const [loc, setLoc] = useState({ lat, lng });

  const handleAddressChange = (lat: number, lng: number) => {
    setLoc({ lat, lng });
    onChange({ lat, lng });
  };

  return (
    <div style={{ position: "relative" }}>
      <PlacesAutocomplete
        placeholder={t("store_form_input_location")}
        handleAddressChange={handleAddressChange}
      />
      <Map
        ref={mapRef}
        google={google}
        zoom={zoom}
        styles={[
          {
            stylers: [{ saturation: -100 }],
          },
        ]}
        initialCenter={loc}
        center={loc}
        disableDefaultUI={true}
        style={{ height: "50vh", position: "absolute", width: "auto" }}
      >
        <Marker position={loc} />
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCcjnlErfb_FmNXZh3lmkrK6X73dpRayf4",
  libraries: ["places"],
})(MapContainer);
