import { DBCOLLECTIONS } from "api/services/dbConstants";
import { AuthUserInfo, StoreManagerStore } from "reduxstore/types";
import firebaseApp from "api/firebase.config";
import { BaseService } from "./BaseService";

class UserService extends BaseService {
  async saveIfNotExists(userInfo: AuthUserInfo) {
    const coll = this.firestore.collection(DBCOLLECTIONS.USERS);
    const userRef = await coll.doc(userInfo.id);
    return coll.firestore.runTransaction(async (transaction) => {
      const user = await transaction.get(userRef);
      const now = firebaseApp.firestore.FieldValue.serverTimestamp();
      if (!user.exists) {
        await transaction.set(userRef, {
          ...userInfo,
          lastLoginAt: now,
          registeredAt: now,
        });
      } else {
        const existing = await user.data();
        await transaction.set(userRef, { ...existing, lastLoginAt: now });
      }
    });
  }

  async fetchUserStores(userId: string): Promise<Array<StoreManagerStore>> {
    const snapshot = await this.firestore
      .collection("users")
      .doc(userId)
      .collection("stores")
      .get();
    const res: Array<StoreManagerStore> = [];
    snapshot.forEach((doc) => {
      const data = doc.data()!;
      res.push({ id: doc.id, role: data["role"] });
    });
    return res;
  }
}

export default new UserService(firebaseApp.firestore());
