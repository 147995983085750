import React from "react";
import { useTranslation } from "react-i18next";
import { Store } from "reduxstore/types";
import Button from "../../../../components/Button";
import { BASE_APP_URL, URLS } from "../../../../utils/constants";
import QRCode from "qrcode.react";
import "./style.css";
import { LogoBig } from "../../../../components/Logo";
import Layout from "../../../../components/FullHeightLayout";
import SMHeader from "../../../../components/SMHeader";

interface Props {
  store: Store;
}

const StoreQRPage = ({ store }: Props) => {
  const { t } = useTranslation();

  const handlePrint = () => {
    window.print();
  };

  const url = `${BASE_APP_URL}${URLS.shopper.store.replace(":id", store.id)}`;

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="sm-store-qr">
        <div style={{ textAlign: "center", paddingTop: "1em" }}>
          <LogoBig />
          <h1>{store.name}</h1>
          <h3>{store.intro}</h3>
        </div>
        <div>{t("qr_print_use_following_code")}</div>
        <div className="sm-store-qr-id">{store.id}</div>
        <div>{t("qr_print_scan_following")}</div>
        <div style={{ padding: "1em" }}>
          <QRCode value={url} size={200} />
        </div>
        <div className="no-print">
          <Button label={t("print")} onClick={handlePrint} />
        </div>
      </div>
    </Layout>
  );
};

export default StoreQRPage;
