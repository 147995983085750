import React from "react";
import cx from "classnames";
import "./style.css";
import Spinner from "../Spinner";

interface Props {
  message?: string;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
}

const FeedbackText = ({ success, error, loading, message }: Props) => {
  if (loading) {
    return (
      <div className="feedback spaced">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className={cx("feedback spaced", {
        "feedback-success": success,
        "feedback-error": error,
      })}
    >
      {message}
    </div>
  );
};

export default FeedbackText;
