import SMTitle from "../../components/SMTitle";
import React from "react";
import { AuthProvider } from "../../reduxstore/types";
import { useFirebase } from "react-redux-firebase";
import { useTranslation } from "react-i18next";
import { ReactComponent as GoogleIcon } from "assets/ic_social_google.svg";
import { ReactComponent as FacebookIcon } from "assets/ic_social_facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/ic_social_twitter.svg";

interface LoginButtonProps {
  label: string;
  provider: AuthProvider;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const firebase = useFirebase();

  const handleLogin = (provider: AuthProvider) => {
    firebase
      .login({
        provider,
        type: "popup",
      })
      .then((res) => console.log("Auth provided", res));
  };

  const LoginButton = ({ label, provider, Icon }: LoginButtonProps) => {
    return (
      <button
        className={`auth-button auth-button-${provider}`}
        onClick={() => handleLogin(provider)}
      >
        <Icon className="auth-button-icon" />
        <div className="auth-button-text">{label}</div>
      </button>
    );
  };

  return (
    <div className="auth-container">
      <SMTitle title={t("auth_title")} subtitle={t("auth_desc")} />
      <div className="auth-buttons">
        <LoginButton
          label={t("login_with_google")}
          provider={"google"}
          Icon={GoogleIcon}
        />
        <LoginButton
          label={t("login_with_facebook")}
          provider={"facebook"}
          Icon={FacebookIcon}
        />
        <LoginButton
          label={t("login_with_twitter")}
          provider={"twitter"}
          Icon={TwitterIcon}
        />
      </div>
      <div className="auth-bottom spaced">{t("auth_bottom_desc")}</div>
    </div>
  );
};

export default LoginPage;
