import React, { useRef } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ImageService from "../../../services/ImageService";
import { useAppSelector } from "../../../reduxstore";
import { Store } from "../../../reduxstore/types";
import iconPhoto from "assets/ic_add_a_photo_24px.svg";
import { LogoBig } from "../../../components/Logo";
import FullHeightLayout, { Bottom } from "../../../components/FullHeightLayout";

interface Props {
  existing?: Store;
  handleSubmitLogo: (imageURL: string) => void;
}

interface FormData {
  file: FileList;
}

const StepLogo = ({ existing, handleSubmitLogo }: Props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm();
  const auth = useAppSelector(({ firebase: { auth } }) => auth);
  const userId = auth.uid;
  const fileRef = useRef<HTMLInputElement | null>(null);

  const isComplete = () => {
    if (existing && existing.imageURL) {
      return true;
    }
    const values = watch();
    return values.file && values.file.length > 0;
  };

  const getImageOrPlaceholder = () => {
    const values = watch();
    if (values.file && values.file.length > 0) {
      return <img src={URL.createObjectURL(values.file[0])} alt="" />;
    }

    if (existing && existing.imageURL) {
      return <img src={existing.imageURL} alt="" />;
    }

    return (
      <div className="store-form-logo-placeholder">
        <div className="store-form-logo-placeholder-l1">
          <div className="store-form-logo-placeholder-l1 store-form-logo-placeholder-l2">
            <div className="store-form-logo-placeholder-l1 store-form-logo-placeholder-l2">
              <img alt="" src={iconPhoto} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onSubmit = (data: FormData) => {
    if (data.file.length > 0) {
      ImageService.uploadImage(userId, data.file[0]).then(handleSubmitLogo);
    } else if (existing) {
      handleSubmitLogo(existing.imageURL);
    } else {
      //error(please select file)
    }
  };

  const triggerFileUpload = () => fileRef.current!.click();

  return (
    <FullHeightLayout>
      <div className="store-form-header spaced">
        <LogoBig />
        <div className="store-form-title">{t(`store_form_logo_title`)}</div>
        <div className="store-form-desc">{t(`store_form_logo_desc`)}</div>
      </div>
      <div className="store-form-logo spaced">
        {getImageOrPlaceholder()}
        <label>
          <Button
            label={t("store_form_logo_upload_btn")}
            color={"secondary"}
            onClick={triggerFileUpload}
          />
          <input
            ref={(e) => {
              register(e);
              fileRef.current = e;
            }}
            name="file"
            accept="image/*"
            type="file"
          />
        </label>
      </div>
      <div className="store-form-logo-btn-hint">
        {t("store_form_logo_upload_hint")}
      </div>
      <Bottom>
        <Button
          label={t("go_to_next_step")}
          disabled={!isComplete()}
          onClick={handleSubmit(onSubmit)}
        />
      </Bottom>
    </FullHeightLayout>
  );
};

export default StepLogo;
