import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SendStatus, Store } from "reduxstore/types";
import SMTitle from "components/SMTitle";
import Button from "components/Button";
import NotificationService from "services/db/NotificationService";
import StoreService from "services/db/StoreService";
import "./style.css";
import { useParams } from "react-router-dom";
import PhoneInput from "./PhoneInput";
import Layout, { Bottom } from "../../../../components/FullHeightLayout";
import { URLS } from "../../../../utils/constants";
import FeedbackText from "../../../../components/FeedbackText";
import SMHeader from "../../../../components/SMHeader";

interface Props {
  store: Store;
}

const AddUserToQueue = ({ store }: Props) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState<string>("");
  const [addedTicketNo, setAddedTicketNo] = useState<string>();
  const [sendStatus, setSendStatus] = useState<SendStatus>("idle");
  const { queueId } = useParams<{ queueId: string }>();
  const history = useHistory();
  const queueService = StoreService.getQueue(store.id, queueId);

  const handleClick = async () => {
    if (phone.length === 0) {
      setSendStatus("invalid");
    } else {
      setSendStatus("sending");
      try {
        const ticketNo = await queueService.doormanAddsUser(phone);
        const customerAhead = await queueService.waitingCount();
        const waitingTime = await queueService.getWaitingTime();
        const sms = {
          to: phone,
          text: t("sms_doorman_add_user", {
            ticketNo,
            storeName: store.name,
            waitingTime: waitingTime * customerAhead,
          }),
        };
        await NotificationService.sms(store.id, sms);
        setSendStatus("succeeded");
        setAddedTicketNo(ticketNo);
      } catch (e) {
        setSendStatus("failed");
      }
    }
  };

  const handlePhoneChange = (phone: string) => {
    if (phone.length === 0) {
      setSendStatus("idle");
    }
    setPhone(phone);
  };

  const getMessage = () => {
    if (sendStatus === "invalid") {
      return (
        <FeedbackText
          message={t("doorman_manual_user_add_invalid_phone")}
          error={true}
        />
      );
    }

    if (sendStatus === "failed") {
      return (
        <FeedbackText
          message={t("doorman_manual_user_add_failed")}
          error={true}
        />
      );
    }

    if (sendStatus === "succeeded") {
      return (
        <div>
          <FeedbackText
            message={t("doorman_manual_user_add_succeeded")}
            success={true}
          />
          <div className="sm-store-adduser-ticketno">{addedTicketNo}</div>
        </div>
      );
    }

    return null;
  };

  const handleBack = () => {
    setSendStatus("idle");
    history.push(
      URLS.storeManager.doorman
        .replace(":id", store.id)
        .replace(":qid", queueId)
    );
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="sm-store-adduser">
        <SMTitle
          title={t("doorman_manual_user_add_title")}
          subtitle={t("doorman_manual_user_add_desc")}
        />
        <div>
          <div className="sm-store-adduser-input spaced">
            <PhoneInput onChange={handlePhoneChange} />
          </div>
          {getMessage()}
          <div className="sm-store-adduser-bottom">
            {sendStatus !== "succeeded" && (
              <Button
                onClick={handleClick}
                label={t("doorman_manual_user_add_send_btn")}
                loading={sendStatus === "sending"}
              />
            )}
          </div>
        </div>
      </div>
      <Bottom>
        <Button
          label={t("doorman_back_to_queue")}
          color="secondary"
          onClick={handleBack}
        />
      </Bottom>
    </Layout>
  );
};

export default AddUserToQueue;
