import React from "react";
import { LogoBig } from "./Logo";

interface Props {
  title: string;
  subtitle: string;
}

const SMTitle = ({ title, subtitle }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 2rem",
      }}
    >
      <LogoBig />
      <div style={{ fontSize: "1.4em", paddingTop: "0.5rem" }}>{title}</div>
      <div style={{ fontSize: "1em", padding: "0.5rem", textAlign: "center" }}>
        {subtitle}
      </div>
    </div>
  );
};

export default SMTitle;
