import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store } from "reduxstore/types";
import Button from "../../../../components/Button";
import { BASE_APP_URL, URLS } from "../../../../utils/constants";
import "./style.css";
import Layout from "../../../../components/FullHeightLayout";
import SMHeader from "../../../../components/SMHeader";
import SMTitle from "../../../../components/SMTitle";

interface Props {
  store: Store;
}

const StoreLinkPage = ({ store }: Props) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const url = `${BASE_APP_URL}${URLS.shopper.store.replace(":id", store.id)}`;

  const copyToClipboard = () => {
    textAreaRef.current?.select();
    textAreaRef.current?.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setCopied(true);
  };

  return (
    <Layout>
      <SMHeader currentStoreId={store.id} />
      <div className="sm-store-share-link">
        <SMTitle
          title={t("share_store_link")}
          subtitle={t("share_store_link_description")}
        />
        <textarea
          ref={textAreaRef}
          className="sm-store-share-link-textarea"
          defaultValue={url}
          readOnly={true}
        />
        <div className="sm-store-share-link-btn">
          {copied ? (
            <span>{t("share_store_link_copy_success")}</span>
          ) : (
            <Button
              color={"secondary"}
              label={t("share_store_link_copy_btn")}
              type="button"
              onClick={copyToClipboard}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default StoreLinkPage;
