import firebaseApp from "api/firebase.config";
import { AnalyticsService } from "../AnalyticsService";

export class BaseService {
  firestore: firebaseApp.firestore.Firestore;
  analyticsService: AnalyticsService;

  constructor(firestore: firebaseApp.firestore.Firestore) {
    this.firestore = firestore;
    this.analyticsService = new AnalyticsService();
  }
}
